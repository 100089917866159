const initialData = {
    tasks: {
        'photo-1': { id: 'photo-1', content: 'Photo page - 1 image' },
        'photo-2': { id: 'photo-2', content: 'Photo page - 2 images' },
        'photo-4': { id: 'photo-4', content: 'Photo page - 4 images' },
        'photo-8': { id: 'photo-8', content: 'Photo page - 8 images' },
    },
    columns: {
        'column-reportLayout': {
            id: 'column-reportLayout',
            title: 'Report Layout',
            taskIds: [],
        },
        'column-media': {
            id: 'column-media',
            title: 'Available Media',
            taskIds: [],
        },
        'column-forms': {
            id: 'column-forms',
            title: 'Available Forms',
            taskIds: ['photo-1','photo-2','photo-4','photo-8'],
        },

    },
    // Facilitate reordering of the columns
    columnOrder: ['column-media', 'column-forms','column-reportLayout'],
};

export default initialData;
