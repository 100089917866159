import React from 'react'
import Uppy from '@uppy/core'
import Webcam from '@uppy/webcam'
import GoogleDrive from '@uppy/google-drive'
import Url from '@uppy/url'
import ScreenCapture from '@uppy/screen-capture'
import Audio from '@uppy/audio'
import Dropbox from "@uppy/dropbox";
import Box from "@uppy/box";
import { toast } from "react-toastify";


import {Dashboard} from '@uppy/react'
import '@uppy/core/dist/style.css'
import '@uppy/webcam/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import '@uppy/screen-capture/dist/style.css'
import '@uppy/audio/dist/style.css'
import SelectInput from "../common/SelectInput";
import Tus from '@uppy/tus'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import userProfile from "../../redux/reducers/userProfile";


let companionUrl=process.env.REACT_APP_COMPANION_URL;


function Upload(props) {

    let companionAuth =   {
        Authorization: `Bearer ${props.userProfile.token}`
    }
    const uppy = new Uppy({
        meta: {type: 'avatar'},
        restrictions: {maxNumberOfFiles: 1000},
        autoProceed: false,

    }).use(GoogleDrive, {companionUrl: companionUrl, companionHeaders: companionAuth}).use(Url,{companionUrl: companionUrl, companionHeaders: companionAuth}).use(Dropbox,{companionUrl: companionUrl, companionHeaders: companionAuth}).use(Box,{companionUrl: companionUrl, companionHeaders: companionAuth});

    let imageMeta = {project: props.selectedProject.projectInfo.id,time:'1234','userId': props.userProfile.userId};

    uppy.setMeta(imageMeta);


    uppy.use(Tus, {
        endpoint: process.env.REACT_APP_UPLOAD_URL, // use your tus endpoint here
        retryDelays: [0, 1000, 3000, 5000],
        chunkSize: 4194304,
        limit: 1,
        withCredentials:true,headers: {'Authorization': 'Bearer '+props.userProfile.token, 'Access-Control-Allow-Credentials': 'true', 'Access-Control-Allow-Headers': 'Location'},

    })



    uppy.use(Audio, {

        showAudioSourceDropdown: true
        // Options
    })

    uppy.use(ScreenCapture, {
        displayMediaConstraints: {
            video: {
                width: 1280,
                height: 720,
                frameRate: {
                    ideal: 3,
                    max: 5,
                },
                cursor: 'motion',
                displaySurface: 'monitor',
            },
        },
        userMediaConstraints: {
            audio: true,
        },
        preferredVideoMimeType: 'video/webm',
    })


    uppy.use(Webcam, {
        onBeforeSnapshot: () => Promise.resolve(),
        countdown: false,
        title: 'Camera',
        modes: [
            'video-audio',
            'video-only',
            'audio-only',
            'picture',
        ],
        mirror: true,
        videoConstraints: {
            facingMode: 'user',
            width: { min: 720, ideal: 1280, max: 1920 },
            height: { min: 480, ideal: 800, max: 1080 },
        },
        showRecordingLength: false,
        preferredVideoMimeType: null,
        preferredImageMimeType: null,
        mobileNativeCamera: false,
        showVideoSourceDropdown: true,
        locale: {},
    })

    uppy.use(Webcam, {
        onBeforeSnapshot: () => Promise.resolve(),
        countdown: false,
        title: 'NoAudio',
        modes: [
            'video-only',
            'picture',
        ],
        mirror: true,
        videoConstraints: {
            facingMode: 'user',
            width: { min: 720, ideal: 1280, max: 1920 },
            height: { min: 480, ideal: 800, max: 1080 },
        },
        showRecordingLength: false,
        preferredVideoMimeType: null,
        preferredImageMimeType: null,
        mobileNativeCamera: false,
        showVideoSourceDropdown: true,
        id: 'WebcamNoAudio',
        locale: {},
    })


    uppy.on('complete', (result) => {
        setTimeout(()=> {props.loadP(props.selectedProject.projectInfo.id); } ,5000);
        toast.success("Upload completed successfully, loading files to project");

    })


    function selectChange(event)
    {
        imageMeta[event.currentTarget.name]=event.currentTarget.value;
        uppy.setMeta(imageMeta);
    }

    let selectControls = props.selectedProject.attributeData.map(att => {

            let selectOptions = att.choices.map((choice) => {
                return ({
                    value: choice, text: choice
                });
            });


            if (att.controlName === 'imageComments') {
                return null;
            } else {

                return (                    <SelectInput onChange={selectChange} key={Math.random()} name={'defaultAttr_' + att.controlName}
                                 label={att.controlLabel} value={imageMeta[att.controlName]} options={selectOptions}/>);
            }
        }
    );




    const myTheme = {
        // Theme object to extends default dark theme.
        fontSize: "15px"
    };



    let filteredMetafields =props.selectedProject.attributeData.filter(att => {

        if(att.controlName==='imageComments') return false;
        return true;
    });

    let myMetafields = filteredMetafields.map(att => {

        return ({id: 'fileAttr_'+att.controlName ,name: att.controlLabel,render: ({ value, onChange, required, form }, h) => {
                    let param1 = ['select', { required, form, style: myTheme, onChange: (ev) => onChange(ev.target.value)}];


                        let selectOptions = att.choices.map((choice) => {
                                return (
                                    h("option", {value: choice}, choice)
                                );
                            }
                        );

                    param1 = [...param1,...selectOptions];
                    return h.apply(null,param1);
                    }
                    }
                 );
        }
    );

    myMetafields = [{id: 'name', name: 'Name', placeholder: 'file name'},...myMetafields];

    let projectName=props.selectedProject.projectInfo.name;

    let uploadText='Drop files here to upload to '+projectName+', %{browseFiles} or import from:';

        return (<div>
                <header className="App-header">
                    <Container>
                <form id='projectDefaults'><Row><Col>
                    {selectControls}

                    <p></p>
                </Col></Row><Row><Col xs={3}></Col><Col xs={9}>
                <Dashboard className="uppyFlex"
                           uppy={uppy} fileManagerSelectionType={"both"} plugins={['Webcam','WebcamNoAudio','GoogleDrive','Dropbox','Box','Url','ScreenCapture','Audio']} metaFields={myMetafields} proudlyDisplayPoweredByUppy={false} locale={{strings:{dropPasteImportFiles: uploadText}}}
                />
                </Col></Row>
                </form></Container>
                </header>
            </div>
        )

}

export default Upload;