import * as types from "../actions/actionTypes";
import initialState from "./initialState";


export default function userProfile(
    state = initialState.userProfile,
    action
) {
    if (action.type === types.LOAD_USERINFO) {
        return action.userProfile;
    }
    return state;
}


