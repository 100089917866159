import * as types from "./actionTypes";
import * as projectApi from "../../api/projectApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

export function loadProjectSuccess(projects) {
    return { type: types.LOAD_PROJECTS_SUCCESS, projects };
}

export function saveAttributeActionSuccess(imageId,savedAttribute) {
    return { type: types.UPDATE_ATTRIBUTE_SUCCESS, imageId, savedAttribute };
}


export function createProjectSuccess(project) {
    return { type: types.CREATE_PROJECT_SUCCESS, project };
}

export function updateProjectSuccess(project) {
    return { type: types.UPDATE_PROJECT_SUCCESS, project };
}

export function deleteProjectOptimistic(project) {
    return { type: types.DELETE_PROJECT_OPTIMISTIC, project };
}

export function changeActiveProjectSuccess(project) {
    return {type: types.CHANGE_ACTIVE_PROJECT_SUCCESS, project};
}

export function loadProjects(token) {

    return function(dispatch) {
        dispatch(beginApiCall());
        return projectApi
            .getProjects(token)
            .then(projects => {
                dispatch(loadProjectSuccess(projects));
                let e = {};
                e.currentTarget = {value: projects[0].id}
                dispatch(changeProject(e,token));
            })
            .catch(error => {
                dispatch(apiCallError(error));
                throw error;
            });
    };
}


export function saveAttributeAction(imageId,attributeData,token) {
    //eslint-disable-next-line no-unused-vars

    return function(dispatch, getState) {

        dispatch(beginApiCall());
        return projectApi
            .saveAttribute(imageId,attributeData,token)
            .then(savedAttribute => {
                     dispatch(saveAttributeActionSuccess(imageId,attributeData));
            })
            .catch(error => {
                dispatch(apiCallError(error));
                throw error;
            });
    };
}

export function saveProject(project,token) {
    //eslint-disable-next-line no-unused-vars
    return function(dispatch, getState) {
        dispatch(beginApiCall());
        return projectApi
            .saveProject(project,token)
            .then(savedProject => {
                project.id
                    ? dispatch(updateProjectSuccess(savedProject))
                    : dispatch(createProjectSuccess(savedProject));

                dispatch(loadProjects((token)));
            })
            .catch(error => {
                dispatch(apiCallError(error));
                throw error;
            });
    };
}

export function deleteProject(project,token) {
    return function(dispatch) {
        // Doing optimistic delete, so not dispatching begin/end api call
        // actions, or apiCallError action since we're not showing the loading status for this.
        dispatch(deleteProjectOptimistic(project));
        return projectApi.deleteProject(project.id,token);
    };
}

export function changeProject(event,token) {
    return function(dispatch) {
        dispatch(beginApiCall());
        return projectApi
            .getProject(event.currentTarget.value,token)
            .then(project => {
                for(let i=0;i<project.fileInfo.length;i++) {
                    if(project.fileInfo[i].imageAttributes!=null) {
                        try {
                            project.fileInfo[i].imageAttributes = JSON.parse(project.fileInfo[i].imageAttributes);
                        } catch {}
                    }
                }

                dispatch(changeActiveProjectSuccess(project));
            })
            .catch(error => {
                dispatch(apiCallError(error));
                throw error;
            });

    }
}