import React from "react";
import {
  BsFillCheckSquareFill,
  BsCSquareFill,
} from 'react-icons/bs';

const EditableRow = ({
  editFormData,
  handleEditFormChange,
  handleCancelClick,
  handleEditFormSubmit
}) => {
  let permOptions = (
    <>
      <option value="0">0</option>
      <option value="1">1</option>
    </>
  );
  if (editFormData == null) return (<></>);
  return (
    <tr key={editFormData.id}>
      <td>
        <input
          type="text"
          name="name"
          value={editFormData.name}
          onChange={handleEditFormChange}
        />
      </td>
      <td className="expand">
        <input
          type="text"
          name="emailAddress"
          value={editFormData.emailAddress}
          onChange={handleEditFormChange}
        />
      </td>
      <td>
        <select
          name="permissionLogin"
          value={editFormData.permissionLogin || 0}
          onChange={handleEditFormChange}
        >
          {permOptions}
        </select>
      </td>

      <td>
        <select
          name="projectAccess"
          value={editFormData.projectAccess || 0}
          onChange={handleEditFormChange}
        >
          {permOptions}
        </select>
      </td>
      <td>
        <select
          name="permissionReview"
          value={editFormData.permissionReview || 0}
          onChange={handleEditFormChange}
        >
          {permOptions}
        </select>
      </td>
      <td>
        <select
          name="permissionUpload"
          value={editFormData.permissionUpload || 0}
          onChange={handleEditFormChange}
        >
          {permOptions}
        </select>
      </td>
      <td>
        <select
          name="permissionBuildReport"
          value={editFormData.permissionBuildReport || 0}
          onChange={handleEditFormChange}
        >
          {permOptions}
        </select>
      </td>
      <td>
        <select
          name="permissionManageProjects"
          value={editFormData.permissionManageProjects || 0}
          onChange={handleEditFormChange}
        >
          {permOptions}
        </select>
      </td>
      <td>
        <select
          name="permissionManageUsers"
          value={editFormData.permissionManageUsers || 0}
          onChange={handleEditFormChange}
        >
          {permOptions}
        </select>
      </td>
      <td>
        <select
          name="permissionManageAssets"
          value={editFormData.permissionManageAssets || 0}
          onChange={handleEditFormChange}
        >
          {permOptions}
        </select>
      </td>
      <td className="fit">
        <span className="actions">
          <BsFillCheckSquareFill
            className="save-btn"
            type="submit"
            onClick={handleEditFormSubmit}
          />

          <BsCSquareFill className="cancel-btn" onClick={handleCancelClick} />
        </span>
      </td>
    </tr>
  );
};

export default EditableRow
