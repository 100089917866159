import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userActions from '../../../redux/actions/userActions';
import * as userApi from '../../../api/userApi'

import ReadonlyRow from './ReadonlyRow'
import EditableRow from './EditableRow';

import './ManageUsers.css'

const UsersPage = ({ userProfile, actions, ...props }) => {
  const [users, setUsers] = useState([]);
  const [editUserId, setEditUserId] = useState(0);
  const [editFormData, setEditFormData] = useState({
    name: null,
    emailAddress: null,
    permissionLogin: null,
    projectAccess: null,
    permissionReview: null,
    permissionUpload: null,
    permissionBuildReport: null,
    permissionManageProjects: null,
    permissionManageUsers: null,
    permissionManageAssets: null,
  });

  useEffect(() => {
    if (userProfile.token.length > 0) {
      userApi.getAllUsers(userProfile.token).then((fetchedUsers) => {
        setUsers(fetchedUsers);
      });
    }
  }, []);

  const handleEditClick = (event, user) => {
    event.preventDefault();
    setEditUserId(user.id);
    const formValues = {
      id: user.id,
      name: user.name,
      emailAddress: user.emailAddress,
      permissionLogin: user.permissionLogin,
      projectAccess: user.projectAccess,
      permissionReview: user.permissionReview,
      permissionUpload: user.permissionUpload,
      permissionBuildReport: user.permissionBuildReport,
      permissionManageProjects: user.permissionManageProjects,
      permissionManageUsers: user.permissionManageUsers,
      permissionManageAssets: user.permissionManageAssets,
    };
    setEditFormData(formValues);
  };

  const handleEditFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute('name');
    const fieldValue = event.target.value;
    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;
    setEditFormData(newFormData);
  };

  const handleCancelClick = () => {
    setEditUserId(null);
  };

  const handleEditFormSubmit = (event) => {
    event.preventDefault();

    const editedUser = {
      id: editUserId,
      name: editFormData.name,
      emailAddress: editFormData.emailAddress,
      permissionLogin: editFormData.permissionLogin,
      projectAccess: editFormData.projectAccess,
      permissionReview: editFormData.permissionReview,
      permissionUpload: editFormData.permissionUpload,
      permissionBuildReport: editFormData.permissionBuildReport,
      permissionManageProjects: editFormData.permissionManageProjects,
      permissionManageUsers: editFormData.permissionManageUsers,
      permissionManageAssets: editFormData.permissionManageAssets,
    };

    // call actions?
    actions.updateUser(editedUser, userProfile.token);    

    const newUserList = [...users];
    const idx = users.findIndex(user => user.Id === editUserId);
    newUserList[idx] = editedUser;
    setUsers(newUserList);
    setEditUserId(0);
  };

  if (!userProfile.permissionManageUsers) {
    return <p>You are not authorized to manage users.</p>;
  } else if (users === undefined || users == null ||users.length === 0) {
    return <p>Loading ....</p>;
  } else {
    return (
      <div className="table-wrapper">
        <form onSubmit={handleEditFormSubmit}>
          <table className="userTable">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Login</th>
                <th>Access</th>
                <th>Review</th>
                <th>Upload</th>
                <th>Build Report</th>
                <th>Manage Projects</th>
                <th>Manage Users</th>
                <th>Manage Assets</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {users.map((usr) => {
                if (usr)
                return (
                  <React.Fragment key={usr.id}>
                    {editUserId === usr.id ? (
                      <EditableRow
                        editFormData={editFormData}
                        handleEditFormChange={handleEditFormChange}
                        handleCancelClick={handleCancelClick}
                        handleEditFormSubmit={handleEditFormSubmit}
                      />
                    ) : (
                      <ReadonlyRow
                        usr={usr}
                        handleEditClick={handleEditClick}
                      />
                    )}
                  </React.Fragment>
                );
              else
                return (<></>)
              })}
            </tbody>
          </table>
        </form>
      </div>
    );
  }
};

UsersPage.propTypes = {
  actions: PropTypes.object.isRequired,
  userProfile: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    fetchedUsers: state.users,
    userProfile: state.userProfile,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      loadUsers: bindActionCreators(userActions.loadUsers, dispatch),
      updateUser: bindActionCreators(userActions.updateUser, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersPage);
