import { useEffect }  from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActions from "../../redux/actions/userActions";
import { InteractionType } from '@azure/msal-browser';
import { useMsal, useMsalAuthentication } from "@azure/msal-react";


function mapStateToProps(state) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return {

        actions: {
            loadUser: bindActionCreators(userActions.loadUser, dispatch),
        }
    };
}


function Authenticate(props) {



    const { instance } = useMsal();

    let msalRequest=null;

    const { result, error: msalError, login: msalLogin } = useMsalAuthentication(InteractionType.Redirect, {
        ...msalRequest,
        account: instance.getActiveAccount(),
        redirectUri: process.env.REACT_APP_SITE_URL
    });

    useEffect(() => {
        if (msalError && msalError.errorMessage.indexOf("AADB2C90118") > -1) {
            const request = {
              authority:
                'https://clearsightidentity.b2clogin.com/clearsightidentity.onmicrosoft.com/B2C_1_PASSWORDRESET_USERFLOW',
            };
            msalLogin(InteractionType.Redirect, request)
        }
    }, [msalError]);

    let apiToken='';

    if(result!=null && result.idToken!=null) {
        apiToken = result.idToken;
    }


    if(apiToken == null  || apiToken.length===0) return;

    const headers = new Headers();
    const bearer = `Bearer ${apiToken}`;
    headers.append("Authorization", bearer);
    headers.append('Content-Type', 'application/json');

    fetch(process.env.REACT_APP_API_URL+'/User', {
        method: 'GET',
        withCredentials: true,
        headers: headers
    }).then(response => response.json())
        .then(
            userProfile => {
                userProfile.token = apiToken;
                props.actions.loadUser(userProfile);

            } // Handle the success response object
        ).catch(

        error =>
        {
            //window.location.href = process.env.REACT_APP_API_URL+'/security/login/';
        }

    );


}

export default connect(mapStateToProps,
    mapDispatchToProps)(Authenticate);

