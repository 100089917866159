import { React } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    Link
} from "react-router-dom";
import SelectInput from "./SelectInput";
import * as projectActions from "../../redux/actions/projectActions";
import { useHistory } from 'react-router-dom'


import { Nav, Navbar, Dropdown, DropdownButton, Button } from 'react-bootstrap';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { loginRequest, b2cPolicies } from '../../authConfig';
import * as reviewActions from "../../redux/actions/reviewActions";




function mapStateToProps(state) {
    return {
        projects: state.projects,
        selectedProject: state.selectedProject,
        loading: state.apiCallsInProgress > 0
    };
}

function mapDispatchToProps(dispatch) {
    return {

        actions: {
            changeProject: bindActionCreators(projectActions.changeProject, dispatch),
            loadProjects: bindActionCreators(projectActions.loadProjects, dispatch),
            saveAttributeSelected: bindActionCreators(reviewActions.saveAttributeSelected, dispatch)
        }
    };
}

function MenuHeader(props) {

    const { instance, inProgress } = useMsal();


    const handleLoginPopup = () => {
        instance
            .loginPopup({
                ...loginRequest,
                redirectUri: '/redirect.html',
            })
            .catch((error) => console.log(error));
    };

    const handleLoginRedirect = () => {
        instance.loginRedirect(loginRequest).catch((error) => console.log(error));
    };

    const handleLogoutRedirect = () => {
        instance.logoutRedirect();
    };

    const handleLogoutPopup = () => {
        instance.logoutPopup({
            mainWindowRedirectUri: '/', // redirects the top level app after logout
        });
    };

    const handleProfileEdit = () => {
        if (inProgress === InteractionStatus.None) {
            instance.acquireTokenRedirect(b2cPolicies.authorities.editProfile);
        }
    };


    if(useHistory().location.pathname==='/Tour') {
        return <></>
    }

    const divStyle = {
        textAlign: 'left'
    }

    let projectOptions = props.projects.map(project => {
        return({
        value: project.id, text: project.name});
    });



    let activeAccount;

    if (instance) {
        activeAccount = instance.getActiveAccount();
    }



    if(props.userProfile.permissionLogin===false){
        return (<div>Currently you do not have access to Reveal.  Please request access</div>)

    }

    let accountName = 'Unknown';
    if (activeAccount) {
        accountName = activeAccount.name ? activeAccount.name : (activeAccount.username ? activeAccount.username : 'Unknown');
    }

    return             <div style={divStyle}><img src = "/conlogo.jpg"  alt="REVEAL App"/><AuthenticatedTemplate>

        <SelectInput onChange={(event) => { props.actions.changeProject(event,props.userProfile.token);         props.actions.saveAttributeSelected({internalShowReviewed: true, internalShowAnnotated:true, internalShowType:'All'}); } } name={'project'} label={'Project'} options={projectOptions} value={props.selectedProject.projectInfo.id}/>       <span style={{float: 'right',paddingLeft: '20px',paddingRight: '20px'}}>{accountName}</span> <Button style={{float: 'right'}} onClick={handleLogoutRedirect} className="btn float-right">Sign Out</Button></AuthenticatedTemplate>        <UnauthenticatedTemplate><Button onClick={handleLoginRedirect} className="btn float-right" style={{float: 'right'}} >Sign In</Button></UnauthenticatedTemplate>

        <ul className="nav nav-tabs">
            {(props.selectedProject.permissionUpload===true) &&
            <li className="nav-item">
                <Link className="nav-link" to="/Upload">Upload</Link>
            </li>}
            {(props.selectedProject.permissionUpload===true ) && <>
                <li className="nav-item">
                    <Link className="nav-link" to="/view">View Data</Link>
                </li>

                <li className="nav-item">
                <Link className="nav-link" to="/filelist">File List</Link>
                </li>
            </>
            }

            {(props.selectedProject.permissionReview===true) &&
            <li className="nav-item">
                <Link className="nav-link" to="/review">Review Data</Link>
            </li>}
            {(props.selectedProject.permissionBuildReport===true) &&
            <li className="nav-item">
                <Link className="nav-link" to="/buildreport">Report</Link>
            </li>}
            {(props.userProfile.permissionManageProjects===true) &&
            <li className="nav-item">
                <Link className="nav-link" to="/project">Manage Projects</Link>
            </li>}
            {(props.userProfile.permissionManageAssets===true) &&
                <li className="nav-item">
                    <Link className="nav-link" to="/assets">Manage Assets</Link>
                </li>}
            {(props.userProfile.permissionManageUsers===true) &&
                <li className="nav-item">
                    <Link className="nav-link" to="/users">Manage Users</Link>
                </li>}

            <li className="nav-item">
                <Link className="nav-link" to="/help">Help</Link>
            </li>



        </ul>
</div>
}


export default connect(mapStateToProps,
    mapDispatchToProps)(MenuHeader);