import * as types from "./actionTypes";
import {REVIEW_UPDATE_DISPLAYED_ACTION} from "./actionTypes";


export function updateCurrentImageAction(currentImage,modState) {
    return { type: types.REVIEW_UPDATE_CURRENT_IMAGE, currentImage,modState };
}

export function updateCurrentSlideAction(currentSlide) {
    return { type: types.REVIEW_UPDATE_CURRENT_SLIDE, currentSlide };
}


export function updateAttributeSelected(attributeSelected) {
    return { type: types.REVIEW_UPDATE_ATTRIBUTE_SELECTED, attributeSelected };
}

export function updateModSelected(modSelected) {
    return { type: types.REVIEW_UPDATE_MODIFIER_SELECTED, modSelected };
}

export function updateDisplayedReportAction(reportSelected) {
    return { type: types.REVIEW_UPDATE_DISPLAYED_ACTION, reportSelected };
}


export function updateTaskChecked(taskChecked) {
    return { type: types.REVIEW_UPDATE_TASK_SELECTED, taskChecked };
}


export function updateCurrentImage(currentImage,modState) {
    return function(dispatch) {


        // actions, or apiCallError action since we're not showing the loading status for this.
        dispatch(updateCurrentImageAction(currentImage,modState));

    };
}

export function updateCurrentSlide(currentSlide) {
    return function(dispatch) {


        // actions, or apiCallError action since we're not showing the loading status for this.
        dispatch(updateCurrentSlideAction(currentSlide));

    };
}


export function setTaskChecked(taskChecked) {
    return function(dispatch) {
        // actions, or apiCallError action since we're not showing the loading status for this.

        dispatch(updateTaskChecked(taskChecked));
    };
}


export function updateDisplayedReport(currentSlide) {
    return function(dispatch) {
        // actions, or apiCallError action since we're not showing the loading status for this.
        dispatch(updateDisplayedReportAction(currentSlide));

    };
}


export function saveAttributeSelected(attributeSelected) {
    return function(dispatch) {


        // actions, or apiCallError action since we're not showing the loading status for this.
        dispatch(updateAttributeSelected(attributeSelected));

    };
}

export function saveModSelected(modSelected) {
    return function(dispatch) {


        // actions, or apiCallError action since we're not showing the loading status for this.
        dispatch(updateModSelected(modSelected));

    };
}