import * as types from './actionTypes';
import * as tenantApi from '../../api/tenantApi';
import { beginApiCall, apiCallError } from './apiStatusActions';

export function loadTenantSuccess(tenants) {
  return { type: types.LOAD_TENANTS_SUCCESS, tenants };
}

export function loadSitesSuccess(sites) {
  return { type: types.LOAD_SITES_SUCCESS, sites};
}

export function loadTenants(token) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return tenantApi
      .getTenants(token)
      .then((tenants) => {
        dispatch(loadTenantSuccess(tenants));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function loadSites(tenantId, token) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return tenantApi.getSiteByTenantId(token, tenantId)
    .then((sites) => {
      dispatch(loadSitesSuccess(sites));
    })
    .catch((error) => {
      dispatch(apiCallError(error))
      throw error;
    })
  }
}
