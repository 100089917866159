import * as React from 'react';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import {Document, Page} from "react-pdf/dist/umd/entry.webpack5";
import {useEffect} from "react";
import * as formApi from "../../api/formApi";

function Report(props) {


function onDocumentLoadSuccess() {

}

function saveReport() {

    let reportTitle='';
    let reportContent='[22,23]';
    let projectId=props.selectedProject.projectInfo.id;
    let userId = props.userProfile.userId

    formApi
        .createReport(props.userProfile.token,reportTitle,reportContent,projectId,userId)
        .then(response => {
            alert(response);
        })
        .catch(error => {
            throw error;
        });
}

    useEffect(() => {

    },[]);

let fileLoad = process.env.REACT_APP_API_URL+"/Report/"+props.selectedProject.projectInfo.id;

return (<div >
    <div className='control-section' >
        <button className="btn btn-info" onClick={saveReport}>Save Report</button>
        <Document file={fileLoad} onLoadSuccess={onDocumentLoadSuccess}   options={{
            disableFontFace: true, standardFontDataUrl: "https://unpkg.com/pdfjs-dist@2.12.313/standard_fonts/",
        }}>
            <Page pageNumber={1} renderForms={true} renderAnnotationLayer={true}  />
        </Document>
    </div>
</div>);

}


export default Report;