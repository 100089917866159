import {handleError, handleResponse} from "./apiUtils";
const baseUrl = process.env.REACT_APP_API_URL + "/Forms/";
const baseUrlTemplates = process.env.REACT_APP_API_URL + "/FormTemplates/";
const baseUrlReport = process.env.REACT_APP_API_URL + "/Report/";

export function createForm(formData,token) {
    return fetch(baseUrl, {
        method: "POST",
        headers: { "content-type": "application/json", 'Authorization': 'Bearer '+token },
        withCredentials:true,
        body: JSON.stringify(formData)
    })
        .then(handleResponse)
        .catch(handleError);
}


export function updateForm(formData,token) {
    return fetch(baseUrl, {
        method: "PUT",
        headers: { "content-type": "application/json", 'Authorization': 'Bearer '+token },
        withCredentials:true,
        body: JSON.stringify(formData)
    })
        .then(handleResponse)
        .catch(handleError);
}


export function getAllTemplates(token) {
    return fetch(baseUrlTemplates, {
        method: "GET",
        headers: { "content-type": "application/json", 'Authorization': 'Bearer '+token },
        withCredentials:true
    })
        .then(handleResponse)
        .catch(handleError);
}

export function getFormData(token,formTitle,project) {
    return fetch(baseUrl+project+'/'+formTitle, {
        method: "GET",
        headers: { "content-type": "application/json", 'Authorization': 'Bearer '+token },
        withCredentials:true
    })
        .then(handleResponse)
        .catch(handleError);
}

export function createReport(token,reportTitle,reportContent,projectId,userId) {

    let dataForm = new FormData();
    dataForm.append('reportTitle',reportTitle);
    dataForm.append('reportContent',reportContent);
    dataForm.append('projectId',projectId);
    dataForm.append('userId', userId);

    return fetch(baseUrlReport, {
        method: "POST",
        headers: {  'Authorization': 'Bearer '+token },
        withCredentials:true,
        body: dataForm

    })
        .then(handleResponse)
        .catch(handleError);
}

export function getReport(token,projectId,userId='') {

    return fetch(baseUrlReport+projectId, {
        method: "GET",
        headers: {  'Authorization': 'Bearer '+token, 'X-SAVE-BY': userId },
        withCredentials:true,
    })
        .then(handleResponse)
        .catch(handleError);
}
