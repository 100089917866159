import * as types from "./actionTypes";
import * as userApi from '../../api/userApi';
import { beginApiCall, apiCallError } from './apiStatusActions';

export function loadUser(userProfile) {
    return { type: types.LOAD_USERINFO, userProfile };
}

export function loadAllUsers(users) {
  return { type: types.LOAD_ALL_USERS_SUCCESS, users };
}

export function deleteUserOptimistic(user) {
  return { type: types.DELETE_USER_OPTIMISTIC, user };
}

export function updateUserOptimistic(user) {
  return { type: types.UPDATE_USER_OPTIMISTIC, user };
}

export function changeActiveUserSuccess(user) {
  return { type: types.CHANGE_ACTIVE_USER_SUCCESS, user };
}

export function loadUsers(token) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return userApi
      .getAllUsers(token)
      .then((users) => {
        console.log('fetched via API and dispatching loadAllUsers', users);
        dispatch(loadAllUsers(users));
        // let e = {};
        // e.currentTarget = { value: users[0].id };
        // dispatch(changeUser(e, token));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function deleteUser(user, token) {
  return function (dispatch) {
    // Doing optimistic delete, so not dispatching begin/end api call
    // actions, or apiCallError action since we're not showing the loading status for this.
    dispatch(deleteUserOptimistic(user));
    return userApi.deleteUser(user.id, token);
  };
}

export function updateUser(user, token) {
  console.log(`excuting update user in userActions, ${user.name}`)
  return function (dispatch) {
    // Doing optimistic delete, so not dispatching begin/end api call
    // actions, or apiCallError action since we're not showing the loading status for this.
    dispatch(updateUserOptimistic(user));
    return userApi.updateUser(user, token);
  };
}

export function changeUser(event, token) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return userApi
      .getAllUsers(token)
      .then((user) => {

        dispatch(changeActiveUserSuccess(user));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}