export const LOAD_AUTHORS_SUCCESS = "LOAD_AUTHORS_SUCCESS";
export const CREATE_PROJECT = "CREATE_PROJECT";
export const LOAD_PROJECTS_SUCCESS = "LOAD_PROJECTS_SUCCESS";
export const LOAD_PROJECT_SUCCESS = "LOAD_PROJECT_SUCCESS";
export const CREATE_PROJECT_SUCCESS = "CREATE_PROJECT_SUCCESS";
export const LOAD_SITES_SUCCESS = "LOAD_SITES_SUCCESS";
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS";
export const CHANGE_ACTIVE_PROJECT_SUCCESS = "CHANGE_ACTIVE_PROJECT_SUCCESS";
export const CHANGE_ACTIVE_USER_SUCCESS = 'CHANGE_ACTIVE_USER_SUCCESS';
export const BEGIN_API_CALL = "BEGIN_API_CALL";
export const API_CALL_ERROR = "API_CALL_ERROR";


export const UPDATE_ATTRIBUTE_SUCCESS = "UPDATE_ATTRIBUTE_SUCCESS";

export const LOAD_USERINFO = "LOAD_USERINFO";
export const LOAD_USERINFO_SUCCESS = "LOAD_USERINFO_SUCCESS";
export const LOAD_ALL_USERS = "LOAD_ALL_USERS";
export const LOAD_ALL_USERS_SUCCESS = "LOAD_ALL_USERS_SUCCESS";
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';

export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGOUT = "USER_LOGOUT";

export const LOAD_TENANTS_SUCCESS = 'LOAD_TENANTS_SUCCESS';

export const REVIEW_UPDATE_CURRENT_IMAGE = "REVIEW_UPDATE_CURRENT_IMAGE";
export const REVIEW_UPDATE_CURRENT_SLIDE = "REVIEW_UPDATE_CURRENT_SLIDE";
export const REVIEW_UPDATE_ATTRIBUTE_SELECTED = "REVIEW_UPDATE_ATTRIBUTE_SELECTED";
export const REVIEW_UPDATE_MODIFIER_SELECTED = "REVIEW_UPDATE_MODIFIER_SELECTED";

export const REVIEW_UPDATE_DISPLAYED_ACTION = "REVIEW_UPDATE_DISPLAYED_ACTION";

export const REVIEW_UPDATE_TASK_SELECTED = "REVIEW_UPDATE_TASK_SELECTED";

// By convention, actions that end in "_SUCCESS" are assumed to have been the result of a completed
// API call. But since we're doing an optimistic delete, we're hiding loading state.
// So this action name deliberately omits the "_SUCCESS" suffix.
// If it had one, our apiCallsInProgress counter would be decremented below zero
// because we're not incrementing the number of apiCallInProgress when the delete request begins.
export const DELETE_PROJECT_OPTIMISTIC = "DELETE_COURSE_OPTIMISTIC";
export const DELETE_USER_OPTIMISTIC = "DELETE_USER_OPTIMISIC";
export const UPDATE_USER_OPTIMISTIC = 'UPDATE_USER_OPTIMISIC';
