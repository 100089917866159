import { MsalProvider } from '@azure/msal-react';

import App from './App';

const  AuthenticatedApp = ({ instance }) => {
    return (
        <MsalProvider instance={instance}>
                <App />
        </MsalProvider>
    );
}

export default AuthenticatedApp;
