import React from "react";
import {
  BsFillPencilFill
} from 'react-icons/bs';

const ReadonlyRow = ({ usr, handleEditClick }) => {
  return (
    <tr key={usr.id}>
      <td>{usr.name}</td>
      <td className="expand">{usr.emailAddress}</td>
      <td>{usr.permissionLogin}</td>
      <td>{usr.projectAccess}</td>
      <td>{usr.permissionReview}</td>
      <td>{usr.permissionUpload}</td>
      <td>{usr.permissionBuildReport}</td>
      <td>{usr.permissionManageProjects}</td>
      <td>{usr.permissionManageUsers}</td>
      <td>{usr.permissionManageAssets}</td>
      <td className="fit">
        <span className="actions">
          <BsFillPencilFill
            className="edit-btn"
            onClick={(event) => {
              handleEditClick(event, usr);
            }}
          />
        </span>
      </td>
    </tr>
  );
};

export default ReadonlyRow