import { handleResponse, handleError } from './apiUtils';

const baseUrl = process.env.REACT_APP_API_URL + '/Tenant';

export function getTenants(token) {
  return fetch(baseUrl, {
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + token },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getSiteByTenantId (token, tenantId) {
  return fetch(`${baseUrl}/site/${tenantId}`, {
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + token },
  })
    .then(handleResponse)
    .catch(handleError);
}
