import React from 'react'

import useKrpano from 'react-krpano-hooks'


const Tour = () => {
    const { containerRef } = useKrpano()

    return <div ref={containerRef} />
}

export default Tour;