import React, {useState} from 'react';
import styled from 'styled-components';
import { Draggable } from 'react-beautiful-dnd';
import { connect } from "react-redux";
import {bindActionCreators} from "redux";
import * as reviewActions from "../../redux/actions/reviewActions";
import {setTaskChecked} from "../../redux/actions/reviewActions";

const Container = styled.div`
  border: 1px solid lightgrey;
  border-radius: 2px;
  padding: 8px;
  margin-bottom: 8px;
  
  background-color: ${props => (props.isDragging ? 'lightgreen' : 'white')};
  border-color: ${props => (props.isRed ? 'red' : 'black')};
  border-width: ${props => (props.isRed ? '4px' : '2px')};
  z-index: 10;
  color: black;
`;



function mapStateToProps(state) {
    return {
        reviewState: state.reviewState
    };
}

function mapDispatchToProps(dispatch) {
    return {
                actions: {
                    updateDisplayedReport: bindActionCreators(reviewActions.updateDisplayedReport, dispatch),
                    setTaskChecked: bindActionCreators(reviewActions.setTaskChecked, dispatch)
                }
    };
}

function Task(props) {


    function updateChecked() {
        let currentState = props.reviewState.taskChecked[props.task.id];

        if(currentState==undefined) {currentState=false};
        currentState=!currentState;
        let newTaskChecked  = {...props.reviewState.taskChecked};
        newTaskChecked[props.task.id] = currentState;
        props.actions.setTaskChecked(newTaskChecked);
    }


    function clickMe(event) {
        if(event.target.dataset.rbdDraggableId!=null && !event.target.dataset.rbdDraggableId.startsWith('photo')) {
            props.actions.updateDisplayedReport(event.target.dataset.rbdDraggableId);
        }

        if(event.target.getAttribute('dragid')!=null) {
            props.actions.updateDisplayedReport(event.target.getAttribute('dragid'));
        }
    }


let isSelected=false;

    if(props.reviewState.reportSelected === props.task.id) {
        isSelected=true;
    }



            return (
                <Draggable draggableId={props.task.id} index={props.index} >
                    {(provided, snapshot) => (
                        <Container onClick={clickMe}
                                   {...provided.draggableProps}
                                   {...provided.dragHandleProps}
                                   innerRef={provided.innerRef}
                                   isDragging={snapshot.isDragging}
                                   isRed = {isSelected}

                        >
                            { props.task.showCheckbox===true &&
                            <input type="checkbox" checked={props.reviewState.taskChecked[props.task.id]} onChange={updateChecked}  />}
                            {props.task.content}<p></p>
                            <img src={props.task.imageURL} dragid={props.task.id} width={100} alt='TaskImage'></img>

                        </Container>
                    )}
                </Draggable>
            );


}
export default connect(mapStateToProps,
    mapDispatchToProps)(Task)