import React, {useEffect,useState,useRef} from 'react'
import ImageGallery from 'react-image-gallery';
import { useHistory} from "react-router-dom";
import SelectInput from "../common/SelectInput";
import { Modal, Button } from "react-bootstrap";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as reviewActions from "../../redux/actions/reviewActions";
import * as projectActions from "../../redux/actions/projectActions";
import { toast } from "react-toastify";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Pannellum, PannellumVideo } from "pannellum-react";


function ReviewData(props) {

	const downloadButton = false;
    const imageBase = props.selectedProject.projectInfo.webURL;
    const sasKey = props.selectedProject.projectInfo.sasKey;
    const updateRef = React.useRef(null)
    const refImg = useRef(null);
    const videoPlayerRef = useRef(null);
    let ri=0;
    if(refImg!=null && refImg.current!=null) {
        ri = refImg.current.getCurrentIndex();
    }

    const [show, setShow] = useState(false);

    const showFilter = props.reviewState.attributeSelected['internalShowType'];
    const setShowFilter = (val1) => {  saveSelectChange('internalShowType',val1); };

    const annotationChecked = props.reviewState.attributeSelected['internalShowAnnotated'];
    const setAnnotationChecked =  (val1) => {  saveSelectChange('internalShowAnnotated',val1); };

    let folderSelected = props.reviewState.attributeSelected['internalFolderSelected'];
    if(folderSelected===undefined) { folderSelected="\\" }
    const setFolderSelected = (val1) => {  saveSelectChange('internalFolderSelected',val1); };

    const checked = props.reviewState.attributeSelected['internalShowReviewed'];
    const setChecked =  (val1) => {  saveSelectChange('internalShowReviewed',val1); };


    const handleClose = () => {
        setShow(false);

    }
    const handleShow = () => setShow(true);

    const [state, setState] = useState({
        showIndex: false,
        showBullets: true,
        infinite: true,
        showThumbnails: true,
        showFullscreenButton: true,
        showGalleryFullscreenButton: true,
        showGalleryPlayButton: false,
        showNav: true,
        isRTL: false,
        slideDuration: 450,
        slideInterval: 2000,
        slideOnThumbnailOver: false,
        thumbnailPosition: 'bottom',
        showVideo: {},
        showFullImage: {},
        useWindowKeyDown: true,
    });

    const history=useHistory();

    useEffect(() => {

        if(images[props.reviewState.currentSlide]==null) {
            props.actions.updateCurrentSlide(0);
        } else {

            if (images != null && images.length > 0 && images[props.reviewState.currentSlide].id != null) {
                props.actions.updateCurrentImage(images[props.reviewState.currentSlide].id, ns(images[props.reviewState.currentSlide].imageAttributes));
            }
        }

//        props.actions.saveAttributeSelected({});
    },[props.selectedProject.projectInfo.id]);

    function _renderImageZoom(item) {

        let imageUrl = item.original;

        if(state.showFullImage[item.original]) {
            imageUrl = item.fullImage;
        }


        if(item.isPanorama) {
            return (
                <Pannellum
                    width="100%"
                    height="500px"
                    image={item.fullImage}
                    pitch={10}
                    yaw={180}
                    hfov={110}
                    autoLoad
                    onLoad={() => {
                        console.log("panorama loaded");
                    }}
                >

                </Pannellum>

            );
        }

        return (
            <div  style={{paddingLeft: '200px'}} >
            <TransformWrapper disablePadding={true} maxScale={100} >
                <TransformComponent  >
                <img className='image-gallery-image' src={imageUrl} />
                </TransformComponent>
            </TransformWrapper>
            </div>

        );
    }


    function _renderVideo(item) {

        return (
            <div>
                {


                    state.showVideo[item.embedUrl] ?
                        <div className='video-wrapper'>
                            <a
                                className='close-video'
                                onClick={()=>{_toggleShowVideo(item.embedUrl)}}
                            >
                            </a>
                            <video crossOrigin="anonymous" ref={videoPlayerRef} width="1120" height="630" controls autoPlay >
                                <source src={item.embedUrl} type="video/mp4" />
                                        Your browser does not support the video tag.
                            </video>
                        </div>
                        :
                        <a onClick={()=> {_toggleShowVideo(item.embedUrl)}}>
                            <div className='play-button'></div>
                            <img className='image-gallery-image' src={item.original} />
                            {
                                item.description &&
                                <span
                                    className='image-gallery-description'
                                    style={{right: '0', left: 'initial'}}
                                >
                    {item.description}
                  </span>
                            }
                        </a>
                }
            </div>
        );
    }


    function changeShowFilter(event){
        setShowFilter(event.target.value);
    }


    function changeFolder(event) {
        setFolderSelected(event.target.value);
    }

    function _toggleShowVideo(url) {
        state.showVideo[url] = !Boolean(state.showVideo[url]);



        setState({
            ...state,showVideo: state.showVideo
        });

    }

    function _toggleShowFullImage(url) {

        state.showFullImage[url] = !Boolean(state.showFullImage[url]);

        setState({
            ...state,showFullImage: state.showFullImage
        });

    }


    function reviewChanged() {
        setChecked(!checked);
    }

    function annotationChanged() {
        setAnnotationChecked(!annotationChecked);
    }


    function  _resetVideo() {

        if(state.showVideo!=null && state.showVideo===true) {
            let ev = {currentTarget: {value: props.selectedProject.projectInfo.id}};
            props.actions.loadP(ev,props.userProfile.token);
        }

        setState({...state,showVideo: {}});
    }

    function selectChange(event) {

        let attributeName = event.currentTarget.name.substring(4);
        let attributeValue = event.currentTarget.value;

        let newModSelected = {...props.reviewState.modSelected};
        newModSelected[attributeName] = attributeValue;

        props.actions.saveModSelected(newModSelected);
    }

    function saveSelectChange(attributeName,attributeValue) {
        let newAttributeSelected = {...props.reviewState.attributeSelected};
        newAttributeSelected[attributeName] = attributeValue;

        props.actions.saveAttributeSelected(newAttributeSelected);

    }

    function markAsReviewed(event) {
        event.preventDefault();


        if(images[ri].imageAttributes['itemReviewed']==null || images[ri].imageAttributes['itemReviewed']===0) {


            let newModSelected = {
                ...props.reviewState.modSelected,
                itemReviewed: 1
            }
            props.actions.saveModSelected(newModSelected);
            props.actions.saveAttribute(images[ri].id, newModSelected , props.userProfile.token);

            props.actions.updateCurrentImage(images[ri].id, ns(newModSelected));

            toast.success("Image marked as reviewed");
        } else {


            let newModSelected2 = {
                ...props.reviewState.modSelected,
                itemReviewed: 0
            }

            props.actions.saveModSelected(newModSelected2);
            props.actions.saveAttribute(images[ri].id, newModSelected2, props.userProfile.token);

            props.actions.updateCurrentImage(images[ri].id, ns(newModSelected2));

            toast.success("Image marked as not reviewed");
        }
    }

    function downloadFile(event) {
        event.preventDefault();



        let downloadPath=imageBase;
        let downloadFilename='';
        for(let i=0;i<props.selectedProject.fileInfo.length;i++) {
            if(props.selectedProject.fileInfo[i].fileId===images[ri].id){
                let p1 = props.selectedProject.fileInfo[i];
                if(p1.fileType==='Video') {
                    downloadPath+='/videos/';
                } else {
                    downloadPath+='/images/';
                }
                downloadFilename = p1.fileName;
                downloadPath+=(p1.fileLocation+sasKey);
            }
        }

        fetch(downloadPath, {
            method: 'GET',

        })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download

                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');

                link.href = url;
                link.setAttribute(
                    'download',
                    downloadFilename,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });

    }


    function openFile(event) {
        event.preventDefault();

        let downloadPath=imageBase;

        for(let i=0;i<props.selectedProject.fileInfo.length;i++) {
            if(props.selectedProject.fileInfo[i].fileId===props.reviewState.currentImage){
                let p1 = props.selectedProject.fileInfo[i];
                if(p1.fileType==='Video') {
                    downloadPath+='/videos/';
                } else {
                    downloadPath+='/images/';
                }
                downloadPath+=(p1.fileLocation+sasKey);
            }
        }

        window.open(
            downloadPath, "_blank");

    }


    function includeInReport(event) {
        event.preventDefault();

        if(images[ri].imageAttributes['itemInReport']==null || images[ri].imageAttributes['itemInReport']===0) {

            let newModSelected = {
                ...props.reviewState.modSelected,
                itemInReport: 1
            }

            props.actions.saveAttribute(images[ri].id, newModSelected, props.userProfile.token);

            props.actions.updateCurrentImage(images[ri].id, ns(newModSelected));

            toast.success("Item included in report");
        } else {

            let newModSelected2 = {
                ...props.reviewState.modSelected,
                itemInReport: 0
            }

            props.actions.saveAttribute(images[ri].id, newModSelected2, props.userProfile.token);

            props.actions.updateCurrentImage(images[ri].id, ns(newModSelected2));

            toast.success("Item removed from report");
        }



    }

    function deleteConfirm(event) {
        event.preventDefault();
        handleShow(true);
    }

    function handleDelete(event) {
        event.preventDefault();
        handleClose();

            props.actions.saveAttribute(images[ri].id, {
                ...images[ri].imageAttributes,
                itemDeleted: 1
            }, props.userProfile.token);

            props.actions.updateCurrentImage(images[ri].id, ns(images[ri].imageAttributes));

            toast.success("File deleted");
    }

    function updateImage(event) {
        event.preventDefault();

        //props.actions.saveAttribute(props.reviewState.currentImage,props.reviewState.modSelected,props.userProfile.token);
        props.actions.saveAttribute(images[ri].id,props.reviewState.modSelected,props.userProfile.token);
        toast.success("Image attributes updated");
    }

    function slideChange(slideNumber) {
        _resetVideo();
        props.actions.saveAttribute(images[ri].id,props.reviewState.modSelected,props.userProfile.token);
        props.actions.updateCurrentImage(images[slideNumber].id);
        props.actions.updateCurrentSlide(slideNumber);
        props.actions.saveModSelected(images[slideNumber].imageAttributes);
        //props.actions.saveAttribute(props.reviewState.currentImage,props.reviewState.modSelected,props.userProfile.token);
    }

    function setAttribute(event) {
        let attributeName = event.currentTarget.name;
        let attributeValue = event.currentTarget.value;

        let newAttributeSelected = {...props.reviewState.attributeSelected};
        newAttributeSelected[attributeName] = attributeValue;

        props.actions.saveAttributeSelected(newAttributeSelected);
    }

    function applyFilter() {
        return props.selectedProject.fileInfo.filter(fn => {
                let ima = fn.imageAttributes;
                if(ima!=null && ima.length>0) {ima=JSON.parse(ima)}

                let at1 = props.reviewState.attributeSelected;


                if(showFilter!=='All') {
                    if(fn.fileType!==showFilter) {
                        return false;
                    }
                }



            if(fn.relativePath!==folderSelected){
                return false;
            }


            if(fn.imageAttributes!=null && fn.imageAttributes['itemDeleted']!=null && fn.imageAttributes['itemDeleted']!==0)
            {
                return false;
            }


            if(!checked && fn.imageAttributes!=null && fn.imageAttributes['itemReviewed']!=null && fn.imageAttributes['itemReviewed']!==0)
                {
                    return false;
                }

                for(let propertyName in at1) {
                    if(!propertyName.startsWith('internal') && at1[propertyName]!=='Not Specified') {
                        if(ima==null || ima[propertyName]!==at1[propertyName]) { return false;}

                    }
                }

                return true;
            }
        );
    }

    let images = [];

    images = applyFilter();

    let selectControls = props.selectedProject.attributeData.map(att => {

                let selectOptions = att.choices.map((choice) =>{
                    return({
                        value: choice, text: choice});
                });



                if(att.controlName==='imageComments') {
                    return null;
                } else {

                    return (
                        <SelectInput key={att.controlName} name={att.controlName} label={att.controlLabel}
                                     value={ns(props.reviewState.attributeSelected[att.controlName])}
                                     onChange={setAttribute} options={selectOptions}/>);
                }
            }
        );

    let folderOptions = { value: '', text: ''};
    if(props.selectedProject.folders!==undefined ) {
        folderOptions = props.selectedProject.folders.map((choice) => {
            return ({
                value: choice, text: choice
            });
        });
    }


    if(props.selectedProject.loaded===false) {
        return "<></>"
    }

    if(images.length===0) return <div>       <header className="App-header"><p>    <SelectInput onChange={changeFolder} key={'folderOptions'} name={'folderOptions'}
                                                                                                label={'Folder'} value={folderSelected}
                                                                                                options={folderOptions} /> {selectControls}Show <select value={showFilter} onChange={changeShowFilter}><option value="All">All</option>
        <option value="Image">Images</option>
        <option value="Video">Videos</option>
        <option value="Misc">Files</option>
    </select></p><p>No images match search criteria</p>                <p><input id={"revBox"}
                                                                                                                                                                  type="checkbox"
                                                                                                                                                                  checked={checked}
                                                                                                                                                                  onChange={reviewChanged}
    /> <label htmlFor="revBox">Show Reviewed</label></p>
    </header></div>

    images = images.map(fn => {

            let galleryFilename =  imageBase + '/gallery/' + fn.fileThumbnail+sasKey;
            let imageFilename =  imageBase + '/images/' + fn.fileThumbnail+sasKey;


            if(annotationChecked && fn.jsonAnnotations!=null && fn.jsonAnnotations.length>0) {
                galleryFilename =  imageBase + '/annotation/' + fn.fileThumbnail.replace('.jpg','.png')+sasKey;
            }

            if(fn.fileType==='Video') {
                return {
                    id: fn.fileId,
                    fileName: fn.fileName,
                    original: galleryFilename,
                    thumbnail: imageBase + '/thumbnails/' + fn.fileThumbnail+sasKey,
                    imageAttributes: fn.imageAttributes,
                    embedUrl: imageBase+'/videos/'+fn.videoFile.toLowerCase()+sasKey,
                    description: 'Click on image to view video',
                    jsonAnnotations: fn.jsonAnnotations,
                    renderItem: _renderVideo
                }

            } else {
                return {
                    id: fn.fileId,
                    fileName: fn.fileName,
                    original: galleryFilename,
                    fullImage: imageFilename,
                    thumbnail: imageBase + '/thumbnails/' + fn.fileThumbnail+sasKey,
                    imageAttributes: fn.imageAttributes,
                    jsonAnnotations: fn.jsonAnnotations,
                    isPanorama: fn.isPanorama,
                    renderItem: _renderImageZoom

                }
            }
        }
    );


    function captureVideo(event) {
        event.preventDefault();

        const v = videoPlayerRef.current;

        if(videoPlayerRef.current==null) {
            toast.error('Please play a video to capture an image');
            return;
        }

        let c = document.createElement('canvas')
        c.height = v.videoHeight || parseInt(v.style.height)
        c.width = v.videoWidth || parseInt(v.style.width)
        const ctx = c.getContext('2d')
        ctx.drawImage(v, 0, 0)
        let myImage = c.toDataURL();

        let dataForm = new FormData();

        let data = atob( myImage.substring( "data:image/png;base64,".length ) ),
            asArray = new Uint8Array(data.length);

        for( let i = 0, len = data.length; i < len; ++i ) {
            asArray[i] = data.charCodeAt(i);
        }

        let blob = new Blob( [ asArray.buffer ], {type: "image/jpeg"} );

        let videoId = props.reviewState.currentImage;

        dataForm.append('myFile', blob,'capture.jpg');
        dataForm.append('videoCapture',videoId);
        dataForm.append('projectId', props.selectedProject.projectInfo.id);

        // Posting myImage to server

        fetch(process.env.REACT_APP_API_URL+'/Files', { // Your POST endpoint
            method: 'POST',
            headers: {
                "Authorization": 'Bearer '+props.userProfile.token
            },
            body: dataForm
        }).then(
            response => {
                return response.text();
            } // if the response is a JSON object
        ).then(
            success => {
                toast.success('Video frame captured to new image');


            }


        ).catch(

            error => {toast.error('Error capturing video frame')}
        );



    }


    function blockSubmit(event)
    {
        event.preventDefault();
    }

    function ns(a) {
        if(a==null) {
            return 'Not Specified';

        }else {
            return a;
        }
    }

    function ns1(a) {
        if(a==null) {
            return '';

        }else {
            return a;
        }
    }


    let modifyControls = props.selectedProject.attributeData.map(att => {

        let selectOptions = att.choices.map((choice) =>{
            return({
                value: choice, text: choice});
        });


        if(att.controlName==='imageComments') {

            return (
                <div key={att.controlName}>
                <p>Comments</p>
                <textarea rows={3}  onChange={selectChange} style={{width: '400px'}} key={'mod_'+att.controlName} name={'mod_'+att.controlName} value={ns1(props.reviewState.modSelected[att.controlName])} />
                    </div>
            );



        } else {

            return (
                <SelectInput onChange={selectChange} key={'mod_' + att.controlName} name={'mod_' + att.controlName}
                             label={att.controlLabel} value={ns(props.reviewState.modSelected[att.controlName])}
                             options={selectOptions}/>
            );

        }

    });


    let isReviewed='No';
    let isAnnotated='No';
    let isInReport='No';

    if(images!=null && images[ri]!=null && images[ri].imageAttributes['itemInReport']!=null && images[ri].imageAttributes['itemInReport']>0) {
        isInReport='Yes';
    }

    if(images!=null && images[ri]!=null && images[ri].jsonAnnotations!=null && images[ri].jsonAnnotations.length>0) {
        isAnnotated='Yes';
    }

    if(images!=null && images[ri]!=null && images[ri].imageAttributes['itemReviewed']!=null && images[ri].imageAttributes['itemReviewed']>0) {
        isReviewed='Yes';
    }

    let fileName='';
if(images[ri]!=null && images[ri].fileName!=null) {
     fileName = images[ri].fileName;
}

    return <div>
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>Please confirm you wish to delete this file</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button className="btn btn-danger" variant="primary" onClick={handleDelete}>
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
        <header className="App-header">
            <Container><Row><Col>
            <form id='projectDefaults'>
                <p>    <SelectInput onChange={changeFolder} key={'folderOptions'} name={'folderOptions'}
                                    label={'Folder'} value={folderSelected}
                                    options={folderOptions} /> {selectControls}Show <select value={showFilter} onChange={changeShowFilter}><option value="All">All</option>
                    <option value="Image">Images</option>
                    <option value="Video">Videos</option>
                    <option value="Misc">Files</option>
                </select></p>
                <p><input id={"revBox"}
                    type="checkbox"
                    checked={checked}
                    onChange={reviewChanged}
                /> <label style={{paddingRight: '20px'}} htmlFor="revBox">Show Reviewed</label><input  id={"annotationBox"}
                                                                       type="checkbox"
                                                                       checked={annotationChecked}
                                                                       onChange={annotationChanged}
                /> <label  htmlFor="annotationBox">Show annotated images</label></p>
                { props.viewOnly!==true && <>

                <p><button className="btn btn-info" onClick={(event)=> {  event.preventDefault();
                    const URL = "/annotate/"+images[ri].id;

                    updateRef.current.click();

                    history.push(URL);}} >Annotate Image</button> <button onClick={markAsReviewed} className="btn btn-primary">Mark as Reviewed</button> <button onClick={includeInReport} className="btn btn-info">Include in report</button> <button onClick={downloadFile} className="btn btn-info">Download</button> <button onClick={openFile} className="btn btn-info">Open in new tab</button> <button onClick={deleteConfirm} className="btn btn-danger">Delete</button></p>
                </>
                }
            </form>
            </Col>
            </Row>
                <Row>
                <Col xs={10}  onKeyDown={blockSubmit}>
                    <p style={{fontSize:'0.7em'}}>{fileName}</p>
                        <ImageGallery items={images} disableKeyDown={true}  lazyLoad={true} onSlide={slideChange} showPlayButton={false} startIndex={props.reviewState.currentSlide} ref={refImg} showBullets={true} />
                    </Col>
                    <Col xs={2}>
                        {modifyControls}
                        <p>Reviewed: {isReviewed}</p>
                        <p>Annotate: {isAnnotated}</p>
                        <p>In Report: {isInReport}</p>
                        { props.viewOnly!==true && <>
                        <p><button onClick={updateImage} ref={updateRef} className="btn btn-primary">Update Data</button></p>
                        <p><button onClick={captureVideo} className="btn btn-info">Capture Video</button></p></>
                            }

                    </Col>
                </Row>
                <div style={{height:'100px'}}></div>

            </Container>
        </header>
    </div>
}

function mapStateToProps(state) {
    return {
        selectedProject: state.selectedProject,
        userProfile: state.userProfile,
        reviewState: state.reviewState

    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            updateCurrentImage: bindActionCreators(reviewActions.updateCurrentImage, dispatch),
            updateCurrentSlide: bindActionCreators(reviewActions.updateCurrentSlide, dispatch),
            saveAttribute: bindActionCreators(projectActions.saveAttributeAction, dispatch),
            saveAttributeSelected: bindActionCreators(reviewActions.saveAttributeSelected, dispatch),
            saveModSelected: bindActionCreators(reviewActions.saveModSelected, dispatch),
            loadP: bindActionCreators(projectActions.changeProject, dispatch)
        }
    };
}


export default connect(mapStateToProps,
    mapDispatchToProps)(ReviewData)
