import initialState from "./initialState";
import {
    REVIEW_UPDATE_ATTRIBUTE_SELECTED,
    REVIEW_UPDATE_CURRENT_IMAGE,
    REVIEW_UPDATE_CURRENT_SLIDE,
    REVIEW_UPDATE_DISPLAYED_ACTION,
    REVIEW_UPDATE_MODIFIER_SELECTED,
    REVIEW_UPDATE_TASK_SELECTED
} from "../actions/actionTypes";


export default function reviewState(
    state = initialState.reviewState,
    action
) {

    switch(action.type) {
        case REVIEW_UPDATE_TASK_SELECTED:
            return {...state, taskChecked: action.taskChecked }
        case REVIEW_UPDATE_CURRENT_IMAGE:
            if(action.modState!=null) {
                return {...state, currentImage: action.currentImage, modSelected: action.modState};
            } else {

                return {...state, currentImage: action.currentImage};
            }

        case REVIEW_UPDATE_CURRENT_SLIDE:
            return {...state, currentSlide: action.currentSlide};

        case REVIEW_UPDATE_ATTRIBUTE_SELECTED:
            return {...state, attributeSelected: action.attributeSelected};

        case REVIEW_UPDATE_MODIFIER_SELECTED:
            return {...state, modSelected: action.modSelected};
        case REVIEW_UPDATE_DISPLAYED_ACTION:
            return {...state, reportSelected: action.reportSelected};
        default:
            return state;
    }

}
