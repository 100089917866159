import React, {useEffect,useState} from 'react'
import { connect } from "react-redux";
import '@atlaskit/css-reset';
import styled from 'styled-components';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import initialData from './initial-data';
import Column from './column';
import * as formApi from "../../api/formApi";
import { toast } from "react-toastify";
import {Document, Page} from "react-pdf/dist/umd/entry.webpack5";
import {bindActionCreators} from "redux";
import * as reviewActions from "../../redux/actions/reviewActions";
import SelectInput from "../common/SelectInput";
import {Button, Modal} from "react-bootstrap";


const Container = styled.div`
  display: flex;
  z-index: 10;
`;




function mapStateToProps(state) {
    return {
        selectedProject: state.selectedProject,
        userProfile: state.userProfile,
        reviewState: state.reviewState
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            updateDisplayedReport: bindActionCreators(reviewActions.updateDisplayedReport, dispatch),
            setTaskChecked: bindActionCreators(reviewActions.setTaskChecked, dispatch)
        }
    };
}




class InnerList extends React.PureComponent {
    render() {
        const { column, taskMap, index } = this.props;
        const tasks = column.taskIds.map(taskId => taskMap[taskId]);
        return <Column column={column} tasks={tasks} index={index} />;
    }
}


function BuildReport(props) {

    const [state, setState] = useState(initialData);
    const [show, setShow] = useState(false);
    const [showDelete, setShowDelete] = useState(false);



    let initialReportId = 0;
    const [selectedReport,setSelectedReport] = useState(0);
    const [reportOnlyChange,setReportOnlyChange] = useState(false);
    const [newReportName,setNewReportName] = useState("");


    const [hideState, setHideState] = useState(0);
    const imageBase = props.selectedProject.projectInfo.webURL;
    const sasKey = props.selectedProject.projectInfo.sasKey;
    const ref = React.useRef();

    function loadForm(formId) {
        // get form fill data from server

        formApi.getFormData(props.userProfile.token,formId,props.selectedProject.projectInfo.id)
            .then(formData => {

                    if(ref.current==null) return;
                    let formFillData = JSON.parse(formData.formFillData);



                    let r = ref.current.getElementsByTagName("input");
                    let s = ref.current.getElementsByTagName("select");
                    let t = ref.current.getElementsByTagName("textarea");



                    Array.from(r).forEach((el) => {

                        let matchingElements = formFillData.filter((el1) => {
                            if(el1.name===el.name) {
                                return true;
                            } else {
                                return false;
                            }
                        });

                        if(matchingElements.length>0) {

                            if (el.type != null && el.type === 'checkbox') {
                                el.checked = matchingElements[0].value;
                            } else {
                                el.value = matchingElements[0].value;
                            }
                        }
                    });

                    Array.from(s).forEach((el) => {

                        let matchingElements = formFillData.filter((el1) => {
                            if(el1.name===el.name) {
                                return true;
                            } else {
                                return false;
                            }
                        });

                        if(matchingElements.length>0) {

                            if (el.type != null && el.type === 'checkbox') {
                                el.checked = matchingElements[0].value;
                            } else {
                                el.value = matchingElements[0].value;
                            }
                        }
                    });

                    Array.from(t).forEach((el) => {

                        let matchingElements = formFillData.filter((el1) => {
                            if(el1.name===el.name) {
                                return true;
                            } else {
                                return false;
                            }
                        });

                        if(matchingElements.length>0) {

                            if (el.type != null && el.type === 'checkbox') {
                                el.checked = matchingElements[0].value;
                            } else {
                                el.value = matchingElements[0].value;
                            }
                        }
                    });


            }
            );
    }

    function saveChanges (event,noDisplay)
    {
        event.preventDefault();

        // if form is open, save contents to server

        if(typeof reportSelected=='string' && reportSelected.startsWith('form')) {

            let reportSelected = props.reviewState.reportSelected;

            let templateId =  reportSelected.substring(5);

            let i = templateId.indexOf('_random');
            if(i>-1) {
                templateId = templateId.substring(0,i);
            }


            let r = ref.current.getElementsByTagName("input");
            let s = ref.current.getElementsByTagName("select");
            let ta = ref.current.getElementsByTagName("textarea");

            let formFillData = [];

            Array.from(r).forEach((el) => {
                if(el.type!=null && el.type==='checkbox') {
                    formFillData.push({name: el.name, value: el.checked})
                } else {
                    formFillData.push({name: el.name, value: el.value})
                }
            });

            Array.from(s).forEach((el) => {
                formFillData.push({name: el.name,value: el.value})
            });

            Array.from(ta).forEach((el) => {
                formFillData.push({name: el.name,value: el.value})
            });

            let formData = {};
            formData.formTitle=reportSelected;
            formData.formFillData = JSON.stringify(formFillData);
            formData.project=props.selectedProject.projectInfo.id;
            formData.templateId = templateId;

            formApi.createForm(formData,props.userProfile.token);


        }

        // Save report layout information to server

        formApi.createReport(props.userProfile.token,'Project Report',state.columns["column-reportLayout"].taskIds,selectedReport);

        if(noDisplay==null)
            toast.success("Changes Saved");

    }


    function downloadFileWeb(downloadFilename,downloadPath) {

        fetch(downloadPath, {
            method: 'GET',

        })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download

                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');

                link.href = url;
                link.setAttribute(
                    'download',
                    downloadFilename,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });


    }

    function downloadReport (event)
    {
        event.preventDefault();

        fetch( process.env.REACT_APP_API_URL + '/GenerateReport/'+selectedReport, {
            method: 'GET',
            headers: { "content-type": "application/json", 'Authorization': 'Bearer '+props.userProfile.token, 'X-GENERATE-BY': props.userProfile.userId },
            withCredentials:true,

        })
            .then((response) => response.text())
            .then((responseText) => {



                toast.success("Report generation complete, downloading report");
                downloadFileWeb('report.pdf',responseText);
                }


            );


        toast.success("Report generation started.  Please wait");
    }

    function downloadReportWithFiles (event)
    {
        event.preventDefault();

        fetch(
          process.env.REACT_APP_API_URL +
            '/GenerateReportDownload/' +
            selectedReport,
          {
            method: 'GET',
            headers: {
              'content-type': 'application/json',
              Authorization: 'Bearer ' + props.userProfile.token,
              'X-DOWNLOAD-BY': props.userProfile.userId,
            },
            withCredentials: true,
          }
        )
          .then((response) => response.text())
          .then((responseText) => {
            toast.success('Report generation complete, downloading files');
            downloadFileWeb('report.zip', responseText);
          });


        toast.success("Report generation started with downloadable files.  Please wait");


     }



    function onDocumentLoadSuccess() {
        setTimeout(()=>props.actions.updateDisplayedReport(props.reviewState.reportSelected),500);
    }



    useEffect(() => {


        let initialReportId = 0;

        if (props.selectedProject.reports != null && props.selectedProject.reports[0] != null) {
            initialReportId = props.selectedProject.reports[0].id;
        }



          if(reportOnlyChange===true) {
              initialReportId = selectedReport;
              setReportOnlyChange(false);
          } else {
              setSelectedReport(initialReportId);
          }




        formApi
            .getAllTemplates(props.userProfile.token)
            .then(templates => {


                let newState = {...state};
                newState.tasks = {
                    'photo-1': { id: 'photo-1', content: 'Photo page- 1 image' },
                    'photo-2': { id: 'photo-2', content: 'Photo page- 2 images' },
                    'photo-4': { id: 'photo-4', content: 'Photo page - 4 images' },
                    'photol-1': { id: 'photol-1', content: 'Photo page - 1 image Landscape' },
                    'photol-4': { id: 'photol-4', content: 'Photo page - 4 images Landscape' },
                };


                newState.columns =
                {
                    'column-reportLayout': {
                    id: 'column-reportLayout',
                        title: 'Report Layout',
                        taskIds: [],
                },
                    'column-media': {
                    id: 'column-media',
                        title: 'Available Images',
                        taskIds: [],
                },
                    'column-forms': {
                    id: 'column-forms',
                        title: 'Available Forms',
                        taskIds: ['photo-1','photo-2','photo-4','photol-1','photol-4'],
                },

                }


                // Load forms into forms column

                for(let i=0;i<templates.length;i++) {
                    let f = 'form-'+String(templates[i].id);
                    newState.tasks[f]={'id':'form-'+String(templates[i].id),'content':templates[i].name}

                }


                let dd2 = templates.map(t =>{
                    return 'form-'+String(t.id);
                });

                let iTasks = ['photo-1','photo-2','photo-4','photol-1','photol-4'];

                newState.columns['column-forms']['taskIds'] = iTasks.concat(dd2);


                // Load media into media column




                let filesForReport = props.selectedProject.fileInfo.filter(fn =>
                {

                    if(fn.imageAttributes!=null && fn.imageAttributes['itemInReport']!=null && fn.imageAttributes['itemInReport']>0 && !fn.imageAttributes.hasOwnProperty('itemDeleted'))
                    {
                        return true;
                    }
                    return false;
                });


                let newTaskChecked = {};

                for(let i=0;i<filesForReport.length;i++) {
                    let f = 'file-'+String(filesForReport[i].fileId);

                    newTaskChecked[f] = false;

                    let fn = filesForReport[i];


                    let galleryFilename =  imageBase + '/thumbnails/' + fn.fileThumbnail+sasKey;

                    if(fn.jsonAnnotations!=null && fn.jsonAnnotations.length>0) {
                        galleryFilename =  imageBase + '/annotation/' + fn.fileThumbnail.replace('.jpg','.png')+sasKey;
                    }

                    newState.tasks[f]={'id':'file-'+String(filesForReport[i].fileId),'content':filesForReport[i].fileName,'imageURL': galleryFilename, 'showCheckbox': true}
                }


                props.actions.setTaskChecked(newTaskChecked);

                newState.columns['column-media']['taskIds'] = filesForReport.map(t =>{
                    return 'file-'+String(t.fileId);
                });







                if(props.selectedProject.reports!=null && props.selectedProject.reports.length>0) {

                    // get report layout from server if exists


                    for (var i3 = 0; i3 < props.selectedProject.reports.length; i3++) {

                        if (props.selectedProject.reports[i3].id === initialReportId) {



                            formApi
                                .getReport(props.userProfile.token, initialReportId, props.userProfile.userId)
                                .then(reportInfo => {

                                    if (reportInfo.reportContent != null && reportInfo.reportContent.length > 0) {

                                        let taskIdList = reportInfo.reportContent.split(',');

                                        newState.columns['column-reportLayout']['taskIds'] = taskIdList;


                                        for (let i = 0; i < taskIdList.length; i++) {
                                            let f = taskIdList[i];

                                            let f1 = f;
                                            let i2 = f1.indexOf('_random');
                                            if (i2 > -1) {
                                                f1 = f1.substring(0, i2);
                                            }


                                            let content = '';
                                            let imageURL = '';

                                            if (newState.tasks[f1] != null && newState.tasks[f1].imageURL != null) {
                                                imageURL = newState.tasks[f1].imageURL;
                                            }

                                            if (newState.tasks[f1] != null && newState.tasks[f1].content != null) {
                                                content = newState.tasks[f1].content;
                                            }


                                            newState.tasks[f] = {'id': f, 'content': content, 'imageURL': imageURL}
                                        }

                                    }
                                    // set new state

                                    setState(newState);

                                })
                                .catch(error => {
                                    throw error;
                                });
                        }
                    }
                }

                if(filesForReport.length>0) {
                    props.actions.updateDisplayedReport('file-' + filesForReport[0].fileId);
                }

            })
            .catch(error => {
                throw error;
            });


            },[props.selectedProject.projectInfo.id,selectedReport]);


    let onDragEnd = result => {
        const { destination, source, draggableId, type } = result;


        if (!destination) {  // User dragged outside the control so delete item

            if(type==='column') {
                return;
            }

            const home = state.columns[source.droppableId];

            if(home.id!=='column-reportLayout') {
                return;
            }


            const newTaskIds = Array.from(home.taskIds);
            newTaskIds.splice(source.index, 1);


            const newHome = {
                ...home,
                taskIds: newTaskIds,
            };

            const newState = {
                ...state,
                columns: {
                    ...state.columns,
                    [newHome.id]: newHome,
                },
            };

            setState(newState);

            return;
        }


        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }

        if (type === 'column') {
            const newColumnOrder = Array.from(state.columnOrder);
            newColumnOrder.splice(source.index, 1);
            newColumnOrder.splice(destination.index, 0, draggableId);

            const newState = {
                ...this.state,
                columnOrder: newColumnOrder,
            };
            setState(newState);
            return;
        }

        const home = state.columns[source.droppableId];
        const foreign = state.columns[destination.droppableId];

        if (home === foreign) {
            const newTaskIds = Array.from(home.taskIds);
            newTaskIds.splice(source.index, 1);
            newTaskIds.splice(destination.index, 0, draggableId);

            const newHome = {
                ...home,
                taskIds: newTaskIds,
            };

            const newState = {
                ...state,
                columns: {
                    ...state.columns,
                    [newHome.id]: newHome,
                },
            };

            setState(newState);
            return;
        }

        if(foreign.id==='column-media' && !draggableId.startsWith('file')) {
            return;
        }

        if(foreign.id==='column-forms' && !draggableId.startsWith('form') && !draggableId.startsWith('photo')) {
            return;
        }

        // moving from one list to another
        const homeTaskIds = Array.from(home.taskIds);
        if(home.id==='column-reportLayout') {
            homeTaskIds.splice(source.index, 1);
        }
        const newHome = {
            ...home,
            taskIds: homeTaskIds,
        };



        const foreignTaskIds = Array.from(foreign.taskIds);


        let newId = draggableId+'_random'+Date.now();

        let newTasks = {};

        if(foreign.id==='column-reportLayout') {

            if(props.reviewState.taskChecked[draggableId]===true) {
                // drag all checked items to report layout

                for(var i=homeTaskIds.length-1;i>=0;i--) {
                    if(props.reviewState.taskChecked[homeTaskIds[i]]===true) {
                        newId = homeTaskIds[i]+'_random'+Date.now();
                        foreignTaskIds.splice(destination.index, 0, newId);
                        newTasks[newId] = {id: newId, content: state.tasks[homeTaskIds[i]].content, imageURL: state.tasks[homeTaskIds[i]].imageURL};
                    }
                }
            } else {
                foreignTaskIds.splice(destination.index, 0, newId);

                newTasks[newId] = {id: newId, content: state.tasks[draggableId].content, imageURL: state.tasks[draggableId].imageURL};

            }
        }

        const newForeign = {
            ...foreign,
            taskIds: foreignTaskIds,
        };


        const newState = {
            ...state,
            columns: {
                ...state.columns,
                [newHome.id]: newHome,
                [newForeign.id]: newForeign,
            },
        };


        if(foreign.id==='column-reportLayout') {
            //newState.tasks = {...state.tasks, [newId]: {id: newId, content: state.tasks[draggableId].content, imageURL: state.tasks[draggableId].imageURL}};
            newState.tasks = {...state.tasks, ...newTasks};
        }

            setState(newState);
    };


    // render code for interaction window here

    let reportSelected = props.reviewState.reportSelected;

    let enablePhoto = false;
    let enableForm = false;
    let photoURL = '';
    let fileLoad='';


    if(typeof reportSelected==='string' || reportSelected instanceof String) {

        let i = reportSelected.indexOf('_random');
        if(i>-1) {
            reportSelected = reportSelected.substring(0,i);
        }

        if (reportSelected.startsWith('file')) {

            let fileNumber = reportSelected.substring(5);

            for(let i=0;i<props.selectedProject.fileInfo.length;i++) {

                if(props.selectedProject.fileInfo[i].fileId==fileNumber) {

                    let fn = props.selectedProject.fileInfo[i];

                     photoURL =  imageBase + '/gallery/' + fn.fileThumbnail+sasKey;

                    if(fn.jsonAnnotations!=null && fn.jsonAnnotations.length>0) {
                        photoURL =  imageBase + '/annotation/' + fn.fileThumbnail.replace('.jpg','.png')+sasKey;
                   }


                }
            }


            enablePhoto = true;
        }

        if (reportSelected.startsWith('form')) {

            fileLoad = process.env.REACT_APP_API_URL + "/FormTemplates/" + reportSelected.substring(5);

            if(props.reviewState.reportSelected.indexOf('_random')>0) {
                loadForm(props.reviewState.reportSelected);
            }

            enableForm = true;
        }

    }



let colLayout="col-6";
let colContent="col-6";


    if(hideState===1 ){
            colLayout="col-4";
            colContent="col-8";
    }



    if(hideState===2){


        enableForm=false;
        enablePhoto=false;
    }




    function hideLayout(event) {
        let h = hideState+1;
        if(h===3) h=0;
        if(enableForm===true) {
            saveChanges(event, true)
        }
        setTimeout(()=>{setHideState(h)},1000);
}


    let selectReportOptions = [];

    if(props.selectedProject.reports!=null && props.selectedProject.reports.length>0) {
         selectReportOptions = props.selectedProject.reports.map((rp) => {
            return ({
                value: rp.id, text: rp.reportTitle
            });
        });
    }

    function changeSelectedReport(event) {
          setReportOnlyChange(true);
           setSelectedReport(event.target.value);
    }


    function addReport(event) {
        event.preventDefault();
        setShow(true);

    }


    function deleteReport(event) {
        event.preventDefault();
        setShowDelete(true);
    }


    function handleClose() {
        setShow(false);
    }

    function reportNameChanged(event) {
        setNewReportName(event.target.value);
    }

    function saveReportButton(event) {
        event.preventDefault();

        let dataForm = new FormData();

        dataForm.append('projectId', props.selectedProject.projectInfo.id);
        dataForm.append('reportTitle', newReportName);
        dataForm.append('updateMode', 'addnew');

        fetch(process.env.REACT_APP_API_URL + '/Report', {
          // Your DELETE endpoint
          method: 'POST',
          body: dataForm,
          headers: {
            Authorization: 'Bearer ' + props.userProfile.token,
            'X-SAVE-BY': props.userProfile.userId,
          },
        })
          .then(
            (response) => {
              return response.json();
            } // if the response is a JSON object
          )
          .then((success) => {
            toast.success('Adding new report');
            props.loadP(props.selectedProject.projectInfo.id);
          })
          .catch((error) => {
            props.loadP(props.selectedProject.projectInfo.id);
          });




        setShow(false);
    }

    function handleRenameReport(event)
    {
        event.preventDefault();

        let dataForm = new FormData();

        dataForm.append('projectId', selectedReport);
        dataForm.append('reportTitle', newReportName);
        dataForm.append('updateMode', 'rename');

        fetch(process.env.REACT_APP_API_URL+'/Report', {
            method: 'POST',
            body: dataForm,
            headers: {
                "Authorization": 'Bearer '+props.userProfile.token,
                "X-SAVE-BY": props.userProfile.userId
            }
        }).then(
            response => {
                return response.json();
            } // if the response is a JSON object
        ).then(
            success => {
                toast.success("Renaming report");
                props.loadP(props.selectedProject.projectInfo.id);
            }


        ).catch(

            error => {props.loadP(props.selectedProject.projectInfo.id); }
        );



    }

    function deleteReportButton(event) {
        event.preventDefault();
    }

    function handleDeleteReport(event ) {
        event.preventDefault();

        fetch(process.env.REACT_APP_API_URL+'/Report/'+selectedReport, { // Your DELETE endpoint
            method: 'DELETE',
            headers: {
                "Authorization": 'Bearer '+props.userProfile.token
            }
        }).then(
            response => {
                return response.json();
            } // if the response is a JSON object
        ).then(
            success => {

                props.loadP(props.selectedProject.projectInfo.id);
            }


        ).catch(

            error => {props.loadP(props.selectedProject.projectInfo.id);            toast.success("Deleting report") }
        );


        setShowDelete(false);
    }

    function deleteClose()
    {
        setShowDelete(false);
    }

    return (<>
        <Modal show={showDelete} onHide={deleteClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>Please confirm you wish to delete this report</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={deleteClose}>
                    Close
                </Button>
                <Button className="btn btn-danger" variant="primary" onClick={handleDeleteReport}>
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Name your report</Modal.Title>
            </Modal.Header>
            <Modal.Body>Please enter a name for the report
                <p></p>
                Enter report name: <input type="text"  value={newReportName} onChange={reportNameChanged} />
                <p></p><button className="btn btn-info" onClick={saveReportButton}>Create new Report</button> <button className="btn btn-info" onClick={handleRenameReport}>Rename Report</button>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
            <div className="row">
                <div className="col-12">
                    <p></p>
                    <SelectInput key="selectReport" name="selectReport" label="Select Report"
                                  options={selectReportOptions} value={selectedReport} onChange={changeSelectedReport}/><button onClick={addReport} className="btn btn-primary">Add Report / Rename Report</button> <button onClick={deleteReport} className="btn btn-danger">Delete Report</button>
                    <p></p>
                    <button onClick={hideLayout} className="btn btn-info">Toggle Layout</button> <button onClick={saveChanges} className="btn btn-info">Save Changes</button> <button  onClick={downloadReport} className="btn btn-info">Download Report</button> <button  className="btn btn-info btn-danger " onClick={downloadReportWithFiles}>Download Report with Files</button>
                    <p></p>
                </div>
            </div>

                <div className="row">
                         <div className={colLayout}  >



                             {(hideState===0 || hideState===2) &&
            <DragDropContext onDragEnd={onDragEnd} >

                <Droppable
                    droppableId="all-columns"
                    direction="horizontal"
                    type="column"
                >
                    {provided => (
                        <Container
                            {...provided.droppableProps}
                            innerRef={provided.innerRef}
                        >
                            {state.columnOrder.map((columnId, index) => {
                                const column = state.columns[columnId];

                                return (
                                    <InnerList
                                        key={column.id}
                                        column={column}
                                        index={index}
                                        taskMap={state.tasks}
                                    />
                                );
                            })}
                            {provided.placeholder}
                        </Container>
                    )}
                </Droppable>

            </DragDropContext>
                                 }
                </div>

                <div id="previewPanel" className={colContent}>
                    {enablePhoto===true &&
                        <div style={{zIndex: -1, display: "flex"}}>
                        <img width={"70%"} src={photoURL} style={{borderStyle: 'solid', borderWidth: '5px', borderColor: "blue"}} ></img>
                        </div>
                    }
                    {enableForm===true &&
                        <Document file={fileLoad} onLoadSuccess={onDocumentLoadSuccess}>
                            <Page pageNumber={1} renderForms={true} renderAnnotationLayer={true} inputRef={ref} />
                        </Document>
                    }
                    {hideState===2 &&
                        <div style={{paddingTop: '50px'}}><span><b>Preview disabled - click toggle layout to enable</b></span></div>
                    }

                </div>

            </div>

        </>);
    }



export default connect(mapStateToProps,
    mapDispatchToProps)(BuildReport)
