import { handleResponse, handleError } from './apiUtils';

const baseUrl = process.env.REACT_APP_API_URL + '/user/';

export function getAllUsers(token) {
  return fetch(baseUrl + 'all', {
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + token },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function updateUser(userPayload, token) {
  return fetch(baseUrl + (userPayload.id), {
    method: 'PUT', // POST for create, PUT to update when id already exists.
    headers: {
      'content-type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    withCredentials: true,
    body: JSON.stringify(userPayload),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function deleteUser(userId, token) {
  return fetch(baseUrl + userId, {
    method: 'DELETE',
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + token },
  })
    .then(handleResponse)
    .catch(handleError);
}
