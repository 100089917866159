import * as types from "../actions/actionTypes";
import initialState from "./initialState";
import update from "react-addons-update";


export default function selectedProject(
    state = initialState.selectedProject,
    action
) {

    if (action.type === types.BEGIN_API_CALL && action.setLoaded!=null) {
        return {...state, loaded:action.setLoaded}
    }

    if (action.type === types.CHANGE_ACTIVE_PROJECT_SUCCESS) {
        action.project.loaded=true;
        return action.project;
    }


    if(action.type === types.UPDATE_ATTRIBUTE_SUCCESS) {


        let imId = parseInt(action.imageId);
        let imId2 =0;

        for(let i=0;i<state.fileInfo.length;i++) {
            if(state.fileInfo[i].fileId===imId) {
                imId2=i;
            }
        }

        return update(state, {
            fileInfo: {
                [imId2]: {
                    imageAttributes: {
                        $set:action.savedAttribute
                    }
                }
            }
        });


    }

    return state;
}


