import React from "react";
import { connect } from "react-redux";
import * as projectActions from "../../../redux/actions/projectActions";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import ProjectList from "./projectList";
import { Redirect } from "react-router-dom";
import Spinner from "../../common/Spinner";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Button, Modal} from "react-bootstrap";

class ProjectsPage extends React.Component {
    state = {
        redirectToAddProjectPage: false,
        showState: false,
        projectToDelete: -1
    };


    handleClose = () => this.setState({...this.state,showState:false});
    handleShow = (project) => this.setState({...this.state,showState:true,projectToDelete:project});

    deleteConfirm = (project) => {
        //event.preventDefault();
        this.handleShow(project);
    }


    handleDeleteProject = async project => {
        toast.success("Project deleted");
        this.handleClose();
        try {
            await this.props.actions.deleteProject(this.state.projectToDelete,this.props.userProfile.token);
        } catch (error) {
            toast.error("Delete failed. " + error.message, { autoClose: false });
            this.handleClose();
        }
    };



    render() {
        return (
            <>
                <Modal show={this.state.showState} onHide={this.handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Please confirm you wish to delete this file</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Close
                        </Button>
                        <Button className="btn btn-danger" variant="primary" onClick={this.handleDeleteProject}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>

                <header className="App-header">
                    <Container><Row><Col xs={1}></Col><Col xs={10}>
                {this.state.redirectToAddProjectPage && <Redirect to="/editproject" />}
                <h2 style={{color: 'white'}}>Projects</h2><div style={{paddingTop:'20px'}}></div>
                {this.props.loading ? (
                    <Spinner />
                ) : (
                    <>
                        <button
                            style={{ marginBottom: 20 }}
                            className="btn btn-primary add-project float-right"
                            onClick={() => this.setState({ redirectToAddProjectPage: true })}
                        >
                            Add new project
                        </button>

                        <ProjectList
                            onDeleteClick={this.deleteConfirm}
                            projects={this.props.projects}
                        />
                    </>
                )}
                    </Col><Col xs={1}></Col></Row></Container>
                </header>
            </>
        );
    }
}

ProjectsPage.propTypes = {
    projects: PropTypes.array.isRequired,
    actions: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
    return {
        projects: [],
        tenants: [],
        loading: state.apiCallsInProgress > 0
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            loadProjects: bindActionCreators(projectActions.loadProjects, dispatch),
            deleteProject: bindActionCreators(projectActions.deleteProject, dispatch)
        }
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectsPage);
