import 'bootstrap/dist/css/bootstrap.css';
import { combineReducers } from "redux";
import projects from "./projectReducer";
import apiCallsInProgress from "./apiStatusReducer";
import selectedProject from "./selectedProject";
import userProfile from "./userProfile";
import tenants from './tenantReducer';
import reviewState from "./reviewState";

const rootReducer = combineReducers({
    projects,
    apiCallsInProgress,
    selectedProject,
    userProfile,
    reviewState,
    tenants
});

export default rootReducer;
