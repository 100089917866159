import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function tenantgReducer(state = initialState.tenants, action) {
  switch (action.type) {
    case types.LOAD_TENANTS_SUCCESS:
      return action.tenants;
    case types.LOAD_SITES_SUCCESS:
      return action.sites;

    default:
      return state;
  }
}

