import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import './index.css';
import AuthenticatedApp from './AuthenticatedApp';
import reportWebVitals from './reportWebVitals';
import configureStore from "./redux/configureStore";
import { Provider as ReduxProvider } from "react-redux";

import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { msalConfig } from './authConfig';


export const msalInstance = new PublicClientApplication(msalConfig);


// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}


msalInstance.addEventCallback((event) => {
    if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
            event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
            event.eventType === EventType.SSO_SILENT_SUCCESS) &&
        event.payload.account
    ) {
        msalInstance.setActiveAccount(event.payload.account);
    }
});


const store = configureStore();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <ReduxProvider store={store}>
    <AuthenticatedApp instance={msalInstance} />
    </ReduxProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
