import React from "react";
import PropTypes from "prop-types";

const selectStyle = {
  marginLeft: '10px',
  marginRight: '20px'
}

const SelectInput = ({
  name,
  label,
  onChange,
  defaultValue,
  value,
  error,
  options,
    myKey
}) => {

 if (defaultValue) console.log(`has default value ${defaultValue}`);
  return (
    <>
      <label htmlFor={name}>{label} </label>
      <select
        name={name}
        value={value}
        onChange={onChange}
        defaultValue={defaultValue}
        style={selectStyle}
      >
        {options.map((option) => {
          return (
            <option key={option.value} value={option.value}>
              {option.text}
            </option>
          );
        })}
      </select>
      {error && <div className="alert alert-danger">{error}</div>}
    </>
  );
};

SelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultOption: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object)
};

export default SelectInput;
