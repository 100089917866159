import React, {useState} from 'react'
import 'tui-image-editor/dist/tui-image-editor.css';
import ImageEditor from '@toast-ui/react-image-editor';
import * as _ from "lodash";
import {Link, useParams} from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { toast } from "react-toastify";
import SelectInput from "../common/SelectInput";
import {Button, Modal} from "react-bootstrap";

const myTheme = {
    // Theme object to extends default dark theme.
     "header.display": "none"
};


let editorInstance;
function Annotate(props) {
    const  editorRef = React.createRef();
    const [templateOptions,setTemplateOptions]=React.useState([])
    const [selectedTemplate,setSelectedTemplate]=React.useState(1)
    const [refreshTemplates,setRefreshTemplates]=React.useState(false)
    const [newTemplateName,setNewTemplateName]=React.useState('')

    let objectsArr = []
    let params = useParams();

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    async function addLayer(layer){

        console.log("addLayer [START]", layer.aCoords);

        let options = {
            aCoords: layer.aCoords,
            angle: layer.angle,
            fill: layer.fill,
            fontSize: layer.fontSize,
            fontWeight: layer.fontWeight,
            fontStyle: layer.fontStyle,
            textDecoration: layer.textDecoration,
            underline: layer.underline,
            height: layer.height,
            stroke: layer.stroke,
            strokeWidth: layer.strokeWidth,
            top: layer.top,
            left: layer.left,
            rotatingPointOffset: layer.rotatingPointOffset,
            rx: layer.rx,
            ry: layer.ry,
            selected: false,
            width: layer.width,
            isRegular: layer.isRegular,
            path: layer.path,
            pathOffset: layer.pathOffset,
            lineCoords: layer.lineCoords,
            originX: layer.originX,
            originY: layer.originY,
            origins: layer.origins,
            position: layer.position,
            startPoint: layer.startPoint,
            scaleX: layer.scaleX,
            scaleY: layer.scaleY,
            styles: { //Duplicate of some properties for addText()
                angle: layer.angle,
                fontSize: layer.fontSize,
                fontWeight: layer.fontWeight,
                fontStyle: layer.fontStyle,
                textDecoration: layer.textDecoration,
                underline: layer.underline,
                fill: layer.fill,
                top: layer.top,
                left: layer.left,
                originX: "left", //layer.originX, The 'left' value is reset to 'center' when there is no change, so we force it here
                originY: "top", //layer.originY, The 'top' value is reset to 'center' when there is no change, so we force it here
                aCoords: layer.aCoords,
                position: layer.aCoords.tl,
            },
        }

        //Add layer Object based on type
        switch (layer.type) {
            case "i-text":
                console.log("addLayer [TEXT]", layer.text);
                await  editorInstance.addText(layer.text, options).then(objectProps => {
                    //Assign all other saved values to the newly created layer
                    layer.originX= "left";
                    layer.originY= "top";
                    _.assign(objectProps, layer);
                    console.log("addLayer [TEXT completed]", objectProps.id, objectProps);
                });
                break;

            case "circle":
            case "triangle":
            case "rect":
                console.log("addLayer [SHAPE]", layer.type);
                await editorInstance.addShape(layer.type, options).then(objectProps => {
                    //Assign all other saved values to the newly created layer
                    _.assign(objectProps, layer);
                    console.log("addLayer [SHAPE completed]", objectProps.id, objectProps);
                });
                break;

            case "icon":
                console.log("addLayer [ICON]", layer.type);

                await editorInstance.addIcon('arrow', options).then(objectProps => {

                    //Assign all other saved values to the newly created layer
                    _.assign(objectProps, layer);
                    console.log("addLayer [ICON completed]", objectProps.id, objectProps);
                });
                break;

            default:
                console.error("addLayer type is not managed", layer, layer.type);
        }

    }

    function templateNameChanged(event)
    {
        setNewTemplateName(event.target.value);
    }

    function openTemplateDialog(event) {
        event.preventDefault();
        handleShow(true);
    }

    function saveTemplateButton(event) {
        event.preventDefault();
        setFilteredTUIObject(editorInstance._graphics.getCanvas()._objects,newTemplateName);
        setTimeout(()=> {setRefreshTemplates(!refreshTemplates)},2000);
    }

    function applyTemplateButton(event) {
        event.preventDefault();

        let asyncAddLayout = [];

        let selTemplate = null;
        let sn = Number(selectedTemplate);


        for(let i3=0;i3<templateOptions.length;i3++) {


            if(templateOptions[i3].value===sn) {
                selTemplate = templateOptions[i3];

            }
        }


        let annotationObject = JSON.parse(selTemplate.annotation);

        let widthRatio = editorInstance._graphics.canvasImage.width/selTemplate.imageWidth;
        let heightRatio = editorInstance._graphics.canvasImage.height/selTemplate.imageHeight;


        // Scale template to fit current image size

        for(let i5=0;i5<annotationObject.length;i5++) {

            annotationObject[i5].top*=heightRatio;
            annotationObject[i5].left*=widthRatio;
            annotationObject[i5].width*=widthRatio;
            annotationObject[i5].height*=heightRatio;

            annotationObject[i5].aCoords.bl.x*=widthRatio;
            annotationObject[i5].aCoords.bl.y*=heightRatio;

            annotationObject[i5].aCoords.br.x*=widthRatio;
            annotationObject[i5].aCoords.br.y*=heightRatio;

            annotationObject[i5].aCoords.tl.x*=widthRatio;
            annotationObject[i5].aCoords.tl.y*=heightRatio;

            annotationObject[i5].aCoords.tr.x*=widthRatio;
            annotationObject[i5].aCoords.tr.y*=heightRatio;

            annotationObject[i5].scaleX*=widthRatio;
            annotationObject[i5].scaleY*=heightRatio;

            annotationObject[i5].fontSize*=widthRatio;

        }

        console.log(annotationObject);

        objectsArr = annotationObject;
        objectsArr.forEach(async (el) => {
            setTimeout(async () => {
                asyncAddLayout.push(await addLayer(el))
            }, 100);
        })
    }

    function deleteTemplateButton(event) {
        event.preventDefault();
        toast.success("Deleted template");
        setTimeout(()=> {setRefreshTemplates(!refreshTemplates)},2000);


        fetch(process.env.REACT_APP_API_URL+'/Drawing/'+selectedTemplate, { // Your DELETE endpoint
            method: 'DELETE',
            headers: {
                "Authorization": 'Bearer '+props.userProfile.token
            }
        }).then(
            response => {
                return response.json();
            } // if the response is a JSON object
        ).then(
            success => {
            }


        ).catch(

            error => {props.loadP(props.selectedProject.projectInfo.id); }
        );

    }

    function setSelectedTemplateFunction(event) {
        setSelectedTemplate(event.target.value);
    }

    function setFilteredTUIObject(editorObjects,templateName){


        //Properties pick
        let modelProp = {
            aCoords:null, //Text
            lineCoords:null,
            angle:null,
            fontSize:null,
            fontWeight:null,
            fill:null,
            fontStyle:null,
            height:null,
            left:null,
            originX:null,
            originY:null,
            origins:null,
            value:null,
            rotatingPointOffset:null,
            text:null,
            textLines:null,
            textDecoration:null,
            top:null,
            underline:null,
            width:null,
            hasBorders:null, //Shape
            rx:null,
            ry:null,
            type:null,
            scaleX:null,
            scaleY:null,
            startPoint:null,
            stroke:null,
            strokeWidth:null,
            path:null,
            pathOffset:null,
            position:null,
            lockSkewingX:null,
            lockSkewingY:null,
        };

        for (let i = 0; i < editorObjects.length; i++) {

            if (editorObjects[i].type !== "path" && editorObjects[i].type !== "line") {
                //Strip off not needed properties like "_", "__", canvas, mouseMoveHandler
                let filteredProp = _.pick(editorObjects[i], _.keys(modelProp));
                objectsArr.push(filteredProp);

            }
        }

        let imageElements=JSON.stringify(objectsArr);
        let myImage = editorInstance.toDataURL();

        let dataForm = new FormData();

        let data = atob( myImage.substring( "data:image/png;base64,".length ) ),
            asArray = new Uint8Array(data.length);

        for( let i = 0, len = data.length; i < len; ++i ) {
            asArray[i] = data.charCodeAt(i);
        }

        let blob = new Blob( [ asArray.buffer ], {type: "image/png"} );


        dataForm.append('myFile', blob,'edited.png');
        dataForm.append('jsonAnnotation', imageElements);
        dataForm.append('fileId', params.slug);

        if(templateName.length>0) {
            dataForm.append('templateName', templateName);
            dataForm.append('imageWidth',editorInstance._graphics.canvasImage.width);
            dataForm.append('imageHeight',editorInstance._graphics.canvasImage.height);

        }

        // Posting myImage to server
        toast.success("Saving...");



        fetch(process.env.REACT_APP_API_URL+'/Drawing', { // Your POST endpoint
            method: 'POST',
            headers: {
                "Authorization": 'Bearer '+props.userProfile.token
            },
            body: dataForm
        }).then(
            response => {
                return response.json();
            } // if the response is a JSON object
        ).then(
            success => {
                props.loadP(props.selectedProject.projectInfo.id);
            }


        ).catch(

            error => {props.loadP(props.selectedProject.projectInfo.id); }
        );




    }
    function handleClickButton()  {
        setFilteredTUIObject(editorInstance._graphics.getCanvas()._objects,'');
    }

    function objectAdded(props){
        console.log(props)

    }
    React.useEffect(()=>{


        editorInstance = editorRef.current.getInstance();
    })

    React.useEffect(()=>{

    fetch(process.env.REACT_APP_API_URL + '/Drawing/', { // Your POST endpoint
        method: 'GET',
        headers: {
            "Authorization": 'Bearer '+props.userProfile.token
        }
    }).then(response => response.json())
        .then(
            success => {

                let tOptions = success.map(t =>{
                    return {text: t.name, value: t.id, annotation: t.annotationData, imageWidth: t.imageWidth, imageHeight: t.imageHeight}
                });
                setTemplateOptions(tOptions);

            } // Handle the success response object
        ).catch(
        error => console.log(error) // Handle the error response object
    );

    },[refreshTemplates]);

    React.useEffect(()=>{  // Get previous JSON attributes and load





        setTimeout(() => {

            fetch(process.env.REACT_APP_API_URL + '/Drawing/' + params.slug, { // Your POST endpoint
                method: 'GET',
                headers: {
                    "Authorization": 'Bearer '+props.userProfile.token
                }
            }).then(response => response.json())
                .then(
                    success => {

                        let asyncAddLayout = [];
                        objectsArr = success;
                        objectsArr.forEach(async (el) => {
                            setTimeout(async () => {
                                asyncAddLayout.push(await addLayer(el))
                            }, 100);
                        })


                    } // Handle the success response object
                ).catch(
                error => console.log(error) // Handle the error response object
            );
        }, 3000);
    },[])

    React.useEffect(()=>{
        if(editorInstance){
            editorInstance.on('redoStackChanged',(props)=>console.log(props,"redoStackChanged"))
            editorInstance.on('objectAdded',(props)=>console.log(props,"objectAdded"))
            editorInstance.on('undoStackChanged',(props)=>console.log(props,"undoStackChanged"))
            editorInstance.on('addText',(props)=>console.log(props,"addText"))
            console.log(editorInstance._initHistory())


        }
    })
    try{

        let downloadPath = props.selectedProject.projectInfo.webURL;
        const sasKey = props.selectedProject.projectInfo.sasKey;


        for(let i=0;i<props.selectedProject.fileInfo.length;i++) {
            if(props.selectedProject.fileInfo[i].fileId===parseInt(params.slug)) {
                let p1 = props.selectedProject.fileInfo[i];
                    downloadPath+='/images/';

                downloadPath+=(p1.fileLocation+sasKey);
            }
        }

        return (<>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Annotation Templates</Modal.Title>
                </Modal.Header>
                <Modal.Body>Please select a template to apply to this image
<p></p><p></p>
                    <SelectInput value={selectedTemplate} onChange={setSelectedTemplateFunction}  name={'Template'} label={'Template'} options={templateOptions}/> <button className="btn btn-danger" onClick={applyTemplateButton}>Apply template</button>
                <p></p>
                  Enter template name:
                    <input type="text"  value={newTemplateName} onChange={templateNameChanged} />
                    <p></p><button className="btn btn-info" onClick={saveTemplateButton}>Save template</button>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={deleteTemplateButton}>Delete template</button>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <div style={{background: "black"}}>

                <Container><Row><Col xs={4}><p></p>
                    <Link className="btn btn-danger" to="/review">Return to gallery</Link> <button className="btn btn-info" onClick={handleClickButton}>Save changes</button></Col><Col xs={2}><button className="btn btn-info" onClick={openTemplateDialog}>Templates</button>                   </Col>
                 </Row><Row><Col xs={12}><p></p><ImageEditor
                    ref={editorRef}
                    includeUI={{
                        objectAdded,
                        loadImage: {
                            path: downloadPath,
                            name: 'SampleImage'
                        },
                        theme: myTheme,
                        menu: ['crop', 'flip', 'rotate', 'draw', 'shape', 'icon','text', 'filter'],
                        uiSize: {
                            width: '1100px',
                            height: '750px',
                        },
                        menuBarPosition: 'right',
                    }}
                    cssMaxHeight={800}
                    cssMaxWidth={790}
                    selectionStyle={{
                        cornerSize: 20,
                        rotatingPointOffset: 70,
                    }}
                    usageStatistics={true}

                />
                </Col></Row><Row style={{background: "black", height: '300px'}}></Row></Container>
            </div></>
        )
    }catch(e){
        console.log(e);
        window.location.reload()

    }
}

export default Annotate