import React, {useState, useEffect} from "react";
import '@atlaskit/css-reset';
import PropTypes from "prop-types";
import TextInput from "../../common/TextInput";
import SelectInput from "../../common/SelectInput";
import {DragDropContext, Droppable} from "react-beautiful-dnd";
import styled from "styled-components";
import Column from "../column";

import * as tenantApi from '../../../api/tenantApi';

const Container = styled.div`
  display: flex;
  z-index: 10;
`;

class InnerList extends React.PureComponent {
    render() {
        const { column, taskMap, index } = this.props;
        const tasks = column.taskIds.map(taskId => taskMap[taskId]);
        return <Column column={column} tasks={tasks} index={index} />;
    }
}

const ProjectForm = ({
                        project,
                        onSave,
                        onChange,
                        userProfile,
                        projects,
                        tenants,
                        onDragEnd,
                        state,
                        addColumn,
                        categoryName,
                        onChangeCategory,
                        saveAttributeButton,

                         saving = false,

                        errors = {}
                    }) => {


    const [ siteOptions, setSiteOptions ] = useState([]);

    let projectOptions = projects.map(project => {

        return({
            value: project.id, text: project.name});
    });
    projectOptions =[ {value: -1, text: "No project template"}].concat(projectOptions);

    
    let tenantOptions = [];
    if (tenants !== null && tenants !== undefined) {
      tenantOptions = tenants.map((t) => {
        return { value: t.id, text: t.name };
      });
    }
    tenantOptions = [{ value: -1, text: 'Choose tenant' }].concat(
      tenantOptions
    );

    function mappingSiteOptoins (payload) {
      let mappedSiteOptions = payload.map((s) => {
        return { value: s.id, text: s.name }
      })      
      return [{ value: -1, text: 'Choose site'}].concat(mappedSiteOptions);
    }

    useEffect(() => {
      if (state.siteOptions) {
        let mappedSiteOptions = mappingSiteOptoins(state.siteOptions);
        setSiteOptions(mappedSiteOptions);  
      }
    }, [state.siteOptions]);    
    

    let tenant = {};
    let defaultTenant = '';    
    let site = {}
    let defaultSite = '';


    if (project !== null && project.tenantName) {
      console.log(`project had tenant: ${project.tenantName}`, project);
      tenant = { id: project.tenantId, name: project.tenantName };      
      defaultTenant = project.tenantId;
      if (project.siteId != null) {
        defaultSite = project.siteId;
      }
    }

    async function onChangeTenant (event) {
      const chosenTenantId = event.target.value;
      let fetchedSiteOptions = await tenantApi.getSiteByTenantId(userProfile.token, chosenTenantId);
      let mappedSiteOptions = mappingSiteOptoins(fetchedSiteOptions);
      console.log(`fetched sites for tenant ${chosenTenantId}`, mappedSiteOptions);
      setSiteOptions(mappedSiteOptions);
      console.log(`new site option for tenant ${chosenTenantId}`, siteOptions);
      onChange(event);
    }
   

    return (
      <form onSubmit={(event) => onSave(event, userProfile)}>
        <header className="App-header">
          <h2 style={{ color: 'white' }}>
            <u>{project.id ? 'Edit' : 'Create new'} Project</u>
          </h2>
          {errors.onSave && (
            <div className="alert alert-danger" role="alert">
              {errors.onSave}
            </div>
          )}
          <p></p>
          {!project.id && (
            <div>
              <SelectInput
                value={project.clone}
                onChange={onChange}
                name={'clone'}
                label={'Project Template'}
                options={projectOptions}
              />
              <p></p>
              <p></p>
            </div>
          )}
          <div className="formRow">
            <TextInput
              name="name"
              label="Name"
              value={project.name}
              onChange={onChange}
              error={errors.name}
              />
          </div>
          <div className="formRow">
            <TextInput
              name="description"
              label="Description"
              value={project.description}
              onChange={onChange}
              error={errors.description}
              />
          </div>
          <p></p>
          <SelectInput
            name="tenant"
            label="Tenant"
            onChange={onChangeTenant}
            options={tenantOptions}
            defaultValue={defaultTenant}
            value={tenant.clone}
          />
          <p></p>
          {tenant != null && siteOptions.length > 0 ? (  
            <div className="formRow">
              
            <SelectInput
              name="site"
              label="Site"
              onChange={onChange}
              options={siteOptions}
              defaultValue={defaultSite}
              value={site.clone}            
            />
            <p></p>
            </div>
          ) : <p></p>}
          <button type="submit" disabled={saving} className="btn btn-primary">
            {saving ? 'Saving...' : 'Save Project Details'}
          </button>

          {project.id && (
            <>
              <p></p>
              <p></p> <u>Category Editor</u>
              <p></p>{' '}
              <TextInput
                name="categoryName"
                label="New Category Name"
                value={categoryName}
                onChange={onChangeCategory}
                error={errors.description}
              />{' '}
              <p></p>{' '}
              <button className="btn btn-info" onClick={addColumn}>
                Add Category
              </button>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable
                  droppableId="all-columns"
                  direction="horizontal"
                  type="column"
                >
                  {(provided) => (
                    <Container
                      {...provided.droppableProps}
                      innerRef={provided.innerRef}
                    >
                      {state.columnOrder.map((columnId, index) => {
                        const column = state.columns[columnId];

                        return (
                          <InnerList
                            key={column.id}
                            column={column}
                            index={index}
                            taskMap={state.tasks}
                          />
                        );
                      })}
                      {provided.placeholder}
                    </Container>
                  )}
                </Droppable>
              </DragDropContext>
              <p></p>
              <p></p>
              <button className="btn btn-info" onClick={saveAttributeButton}>
                Save category changes
              </button>
            </>
          )}
        </header>
      </form>
    );
};

ProjectForm.propTypes = {
    project: PropTypes.object.isRequired,
    errors: PropTypes.object,
    onSave: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    saving: PropTypes.bool
};

export default ProjectForm;
