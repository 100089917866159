import { handleResponse, handleError } from "./apiUtils";

const baseUrl = process.env.REACT_APP_API_URL + "/project/";
const baseUrlAttribute = process.env.REACT_APP_API_URL + "/ImageAttributes/";



export function getProjects(token) {

    return fetch(baseUrl,
        {
        withCredentials:true,headers: {'Authorization': 'Bearer '+token}
        })
        .then(handleResponse)
        .catch(handleError);
}

export function getProject(projectId,token) {

    return fetch(baseUrl + projectId, { method: "GET",
        withCredentials:true,headers: {'Authorization': 'Bearer '+token}
    })
        .then(handleResponse)
        .catch(handleError);
}

export function saveAttribute(imageId,attributeData,token) {

    let fd = new FormData();
    fd.append('imageId',imageId);
    fd.append('attributeData',JSON.stringify(attributeData));

    return fetch(baseUrlAttribute, {
        method: 'POST',
        headers: { 'authorization': 'Bearer ' + token },
        withCredentials:true,
        body: fd
    })
        .then(handleResponse)
        .catch(handleError);
}


export function saveProject(project,token) {
    return fetch(baseUrl + (project.id || ""), {
        method: project.id ? "PUT" : "POST", // POST for create, PUT to update when id already exists.
        headers: { "content-type": "application/json", 'Authorization': 'Bearer '+token },
        withCredentials:true,
        body: JSON.stringify(project)
    })
        .then(handleResponse)
        .catch(handleError);
}

export function deleteProject(projectId,token) {
    return fetch(baseUrl + projectId, { method: "DELETE",
        withCredentials:true,headers: {'Authorization': 'Bearer '+token}
    })
        .then(handleResponse)
        .catch(handleError);
}
