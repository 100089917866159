import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {changeActiveProjectSuccess, loadProjects, saveProject} from "../../../redux/actions/projectActions";
import { loadTenants, loadSites } from '../../../redux/actions/tenantActions';
import {updateDisplayedReport} from "../../../redux/actions/reviewActions";


import PropTypes from "prop-types";
import ProjectForm from "./projectForm";
import Spinner from "../../common/Spinner";
import { toast } from "react-toastify";
import * as projectApi from "../../../api/projectApi";
import * as tenantApi from "../../../api/tenantApi";
import {Button, Modal} from "react-bootstrap";
import TextInput from "../../common/TextInput";
import {handleError, handleResponse} from "../../../api/apiUtils";




const newProject = {
    id: null,
    title: "",
    category: ""
};

let myProject=0;

export function ManageProjectPage({
  projects,
  tenants,
  loadAuthors,
  loadProjects,
  loadTenants,
  saveProject,
  history,
  userProfile,
  updateDisplayedReport,
  ...props
}) {
  const [project, setProject] = useState({ ...props.project });
  const [errors, setErrors] = useState({});
  const [saving, setSaving] = useState(false);
  const [categoryName, setCategoryName] = useState('');
  const [show, setShow] = useState(false);
  const [newItemText, setNewItemText] = useState('');
  const [tenant, setTenant] = useState({});

  const handleClose = () => {
    updateDisplayedReport('none');
    setShow(false);
  };

  function handleCreate(event) {
    event.preventDefault();
    let rs = props.reviewState.reportSelected;

    if (newItemText.length === 0) {
      toast.error('Please enter a choice name');
      return;
    }

    if (rs.length > 0 && rs.startsWith('addnew-')) {
      rs = rs.replace('addnew-', '');

      let newState = { ...state };
      let taskName = 'task-new-' + Object.keys(state.tasks).length;

      newState.tasks = { ...state.tasks };
      newState.columns = { ...state.columns };

      newState.tasks[taskName] = { id: taskName, content: newItemText };
      newState.columns[rs].taskIds.push(taskName);

      setNewItemText('');
      setState(newState);

      handleClose();
    }
  }

  const handleShow = () => setShow(true);

  const initialData = {
    tasks: {},
    columns: {},
    // Facilitate reordering of the columns
    columnOrder: [],
  };

  const [state, setState] = useState(initialData);

  if (props.reviewState.reportSelected != null) {
    //
    let rs = props.reviewState.reportSelected;

    if (rs.length > 0 && rs.startsWith('addnew-') && show === false) {
      handleShow();
    }
  }

  function saveAttributeButton(event) {
    event.preventDefault();

    let arrayData = [];

    let columnKeys = Object.keys(state.columns);

    for (let i8 = 0; i8 < columnKeys.length; i8++) {
      let choiceArray = [];

      for (
        let i9 = 0;
        i9 < state.columns[columnKeys[i8]].taskIds.length;
        i9++
      ) {
        let a2 = state.tasks[state.columns[columnKeys[i8]].taskIds[i9]].content;
        if (a2 !== 'Not Specified' && a2 !== '+ Add New') choiceArray.push(a2);
      }

      let o1 = {
        controlName: state.columns[columnKeys[i8]].id,
        controlLabel: state.columns[columnKeys[i8]].title,
        choices: choiceArray,
      };
      arrayData.push(o1);
    }

    let fd = new FormData();

    fd.append('attributeData', JSON.stringify(arrayData));

    const baseUrlAttribute =
      process.env.REACT_APP_API_URL + '/ImageAttributes/' + myProject;

    toast.success('Updated project categories');

    return fetch(baseUrlAttribute, {
      method: 'PUT',
      headers: { authorization: 'Bearer ' + userProfile.token },
      withCredentials: true,
      body: fd,
    })
      .then(handleResponse)
      .catch(handleError);
  }

  function setText(event) {
    setNewItemText(event.target.value);
  }
  let onDragEnd = (result) => {
    const { destination, source, draggableId, type } = result;

    if (!destination) {
      // User dragged outside the control so delete item

      if (type === 'column') {
        let newState = { ...state };
        let columnName = newState.columnOrder[source.index];

        for (
          let i7 = 0;
          i7 < newState.columns[columnName].taskIds.length;
          i7++
        ) {
          delete newState.tasks[newState.columns[columnName].taskIds[i7]];
        }

        delete newState.columns[columnName];
        newState.columnOrder.splice(source.index, 1);

        setState(newState);
        return;
      }

      if (type !== 'column' && source.index === 0) {
        return;
      }

      const home = state.columns[source.droppableId];
      const newTaskIds = Array.from(home.taskIds);
      newTaskIds.splice(source.index, 1);

      const newHome = {
        ...home,
        taskIds: newTaskIds,
      };

      const newState = {
        ...state,
        columns: {
          ...state.columns,
          [newHome.id]: newHome,
        },
      };

      setState(newState);

      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    if (type === 'column') {
      const newColumnOrder = Array.from(state.columnOrder);
      newColumnOrder.splice(source.index, 1);
      newColumnOrder.splice(destination.index, 0, draggableId);

      const newState = {
        ...state,
        columnOrder: newColumnOrder,
      };
      setState(newState);
      return;
    }

    const home = state.columns[source.droppableId];
    const foreign = state.columns[destination.droppableId];

    if (home === foreign) {
      if (source.index === 0) return;

      const newTaskIds = Array.from(home.taskIds);
      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, draggableId);

      const newHome = {
        ...home,
        taskIds: newTaskIds,
      };

      const newState = {
        ...state,
        columns: {
          ...state.columns,
          [newHome.id]: newHome,
        },
      };

      setState(newState);
      return;
    }

    // moving from one list to another
    const homeTaskIds = Array.from(home.taskIds);
    const newHome = {
      ...home,
      taskIds: homeTaskIds,
    };

    const foreignTaskIds = Array.from(foreign.taskIds);

    let newId = draggableId + '_random' + Date.now();

    const newForeign = {
      ...foreign,
      taskIds: foreignTaskIds,
    };

    const newState = {
      ...state,
      columns: {
        ...state.columns,
        [newHome.id]: newHome,
        [newForeign.id]: newForeign,
      },
    };

    setState(newState);
  };
  useEffect(() => {
    let newState = { ...state };
    newState.tasks = {};

    newState.columns = {};

    newState.columnOrder = [];

    if (userProfile.token != null && userProfile.token.length > 0 && props.project.id) {
      projectApi
        .getProject(props.project.id, userProfile.token)
        .then(async (loadedProject) => {
          if (loadedProject.projectInfo && loadedProject.projectInfo.tenantId) {
            const projectSites = await tenantApi.getSiteByTenantId(userProfile.token, loadedProject.projectInfo.tenantId);
            newState.siteOptions = projectSites;
          } else {
            newState.siteOptions = [];
          }
          if (loadedProject.attributeData != null) {
            let i7 = 0;

            for (let i5 = 0; i5 < loadedProject.attributeData.length; i5++) {
              let p3 = loadedProject.attributeData[i5];

              // create tasks

              let tid = [];
              for (let i6 = 0; i6 < p3.choices.length; i6++) {
                let ct = p3.choices[i6];
                if (ct !== 'Not Specified') {
                  let taskName = 'task-' + i7;
                  i7++;
                  tid.push(taskName);
                  newState.tasks[taskName] = { id: taskName, content: ct };
                }
              }

              let tn = 'addnew-' + p3.controlName;
              newState.tasks[tn] = {
                id: tn,
                content: '+ Add New',
              };
              tid.unshift(tn);

              if (p3.controlName !== 'imageComments') {
                newState.columns[p3.controlName] = {
                  id: p3.controlName,
                  title: p3.controlLabel,
                  taskIds: tid,
                };
                newState.columnOrder.push(p3.controlName);
              }
            }
          }

          console.log(newState);
          setState(newState);
        })
        .catch((error) => {
          throw error;
        });
    }
  }, [props.project]);

  function addColumn(event) {
    event.preventDefault();

    if (categoryName === null || categoryName.length === 0) {
      toast.error('Please enter a category column name');
      return;
    }

    let newState = { ...state };

    let newId =
      categoryName.replaceAll(' ', '') + Math.floor(Math.random() * 100000);

    let tn = 'addnew-' + newId;
    newState.tasks[tn] = {
      id: tn,
      content: '+ Add New',
    };

    let tid = [];
    tid.unshift(tn);

    newState.columns[newId] = {
      id: newId,
      title: categoryName,
      taskIds: tid,
    };

    newState.columnOrder.push(newId);

    setState(newState);
  }

  function onChangeCategory(event) {
    setCategoryName(event.target.value);
  }

  async function handleChange(event) {
    const { name, value } = event.target;

    console.log(name, value);
    if (name === 'tenant') {
      const foundTenant = tenants.find(t => t.id.toString() === value.toString());
      
      if (foundTenant) {
        setProject((prevProject) => ({
          ...prevProject,
          tenantId: value,
          tenantName: foundTenant.name,
        }))
      }
    } else if (name === 'site') {
      const foundSite = state.siteOptions.find(s => s.id.toString() === value.toString())
      if (foundSite) {
        console.log(`using ${name}`, foundSite);
        setProject((prevProject) => ({
          ...prevProject,
          siteId: value,
          siteName: foundSite.name,
        }))
      }
    } else {
      setProject((prevProject) => ({
        ...prevProject,
        [name]: value,      
      }));
    }
  }

  function formIsValid() {
    const { name, description } = project;
    const errors = {};

    if (!name) errors.name = 'Project name is required.';
    if (!description) errors.description = 'Description is required';

    setErrors(errors);
    // Form is valid if the errors object still has no properties
    return Object.keys(errors).length === 0;
  }

  function handleSave(event, userProfile) {
    event.preventDefault();
    if (!formIsValid()) return;
    setSaving(true);

    console.log('saving project', project)
    saveProject(project, userProfile.token)
      .then(() => {
        toast.success('Project saved.');
        setSaving(false);
        history.push('/project');
      })
      .catch((error) => {
        setSaving(false);
        setErrors({ onSave: error.message });
      });
  }
  return projects.length === 0 ? (
    <Spinner />
  ) : (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Choice description</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TextInput
            name="newItemText"
            label="Choice name: "
            value={newItemText}
            onChange={setText}
            error={errors.name}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCreate}>
            Create Choice
          </Button>
        </Modal.Footer>
      </Modal>

      <ProjectForm
        project={project}
        tenant={tenant}
        errors={errors}
        onChange={handleChange}
        onSave={handleSave}
        saving={saving}
        userProfile={userProfile}
        projects={projects}
        tenants={tenants}
        onDragEnd={onDragEnd}
        state={state}
        addColumn={addColumn}
        onChangeCategory={onChangeCategory}
        saveAttributeButton={saveAttributeButton}
      />
    </>
  );
}

ManageProjectPage.propTypes = {
    project: PropTypes.object.isRequired,
    projects: PropTypes.array.isRequired,
    loadProjects: PropTypes.func.isRequired,
    saveProject: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired
};

export function getProjectBySlug(projects, slug) {
    return projects.find(project => project.id.toString() === slug.toString()) || null;
}

function mapStateToProps(state, ownProps) {
    const slug = ownProps.match.params.slug;
    myProject = slug;

    const project =
        slug && state.projects.length > 0
            ? getProjectBySlug(state.projects, slug)
            : newProject;
    return {
      project,
      projects: state.projects,
      tenants: state.tenants,
      userProfile: state.userProfile,
      reviewState: state.reviewState,
    };
}

const mapDispatchToProps = {
    loadProjects,
    saveProject,
    loadTenants,
    updateDisplayedReport
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ManageProjectPage);
