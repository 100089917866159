import { React, useEffect } from 'react';
import { connect } from 'react-redux';
import MenuHeader from './components/common/MenuHeader';
import Upload from './components/pages/Upload';
import Annotate from './components/pages/Annotate';
import ReviewData from './components/pages/ReviewData';
import Authenticate from './components/common/Authenticate';
import Tour from './components/pages/Tour';
import ManageProjectPage from './components/pages/projects/manageProjectPage';
import ProjectsPage from './components/pages/projects/projectsPage';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Report from './components/pages/Report';
import Help from './components/pages/Help';
import BuildReport from './components/pages/BuildReport';
import FileList from './components/pages/FileList';

import { AuthenticatedTemplate } from '@azure/msal-react';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import './App.css';
import { bindActionCreators } from 'redux';
import * as projectActions from './redux/actions/projectActions';
import * as tenantActions from './redux/actions/tenantActions';
import * as userActions from './redux/actions/userActions';
import ManageAssets from './components/pages/ManageAssets';
import ManageUsers from './components/pages/users/ManageUsers';

function mapStateToProps(state) {
  return {
    projects: state.projects,
    selectedProject: state.selectedProject,
    loading: state.apiCallsInProgress > 0,
    userProfile: state.userProfile,
    tenants: state.tenants,
    users: state.users,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      changeProject: bindActionCreators(projectActions.changeProject, dispatch),
      loadProjects: bindActionCreators(projectActions.loadProjects, dispatch),
      saveAttributeAction: bindActionCreators(
        projectActions.saveAttributeAction,
        dispatch
      ),
      loadTenants: bindActionCreators(tenantActions.loadTenants, dispatch),
      loadUsers: bindActionCreators(userActions.loadUsers, dispatch),
    },
  };
}

function App(props) {
  function loadCurrentProject(projectNum) {
    const { actions } = props;

    let ev = { currentTarget: { value: projectNum } };

    actions.changeProject(ev, props.userProfile.token).catch((error) => {
      console.log('Loading project failed' + error);
    });
  }

  useEffect(() => {
    const { projects, tenants, actions } = props;

    if (
      projects.length === 0 &&
      props.userProfile.token != null &&
      props.userProfile.token.length > 0
    ) {
      actions.loadProjects(props.userProfile.token).catch((error) => {
        console.log('Loading projects failed' + error);
      });
    }

    if (
      tenants.length === 0 &&
      props.userProfile.token !== null &&
      props.userProfile.token.length > 0
    ) {
      console.log('loading tenants from AIM');
      actions.loadTenants(props.userProfile.token).catch((error) => {
        console.log('Loading tenants failed' + error);
      });
    }
  });

  return (
    <div className="App">
      <Authenticate />
      <Router>
        <MenuHeader
          userProfile={props.userProfile}
          selectedProject={props.selectedProject}
        ></MenuHeader>
        <ToastContainer />

        {props.userProfile.permissionLogin === true && (
          <AuthenticatedTemplate>
            <Switch>
              <Route path="/upload">
                <Upload
                  selectedProject={props.selectedProject}
                  userProfile={props.userProfile}
                  loadP={loadCurrentProject}
                />
              </Route>
              <Route path="/review">
                <ReviewData viewOnly={false} />
              </Route>
              <Route path="/view">
                <ReviewData viewOnly={true} />
              </Route>
              <Route path="/filelist">
                <FileList />
              </Route>
              <Route path="/annotate/:slug">
                <Annotate
                  userProfile={props.userProfile}
                  selectedProject={props.selectedProject}
                  loadP={loadCurrentProject}
                />
              </Route>
              <Route path="/tour">
                <Tour userProfile={props.userProfile} />
              </Route>
              <Route path="/project/:slug" component={ManageProjectPage} />
              <Route path="/editproject" component={ManageProjectPage} />
              <Route path="/project">
                <ProjectsPage
                  userProfile={props.userProfile}
                  tenants={props.tenants}
                />
              </Route>
              <Route path="/report">
                <Report
                  selectedProject={props.selectedProject}
                  userProfile={props.userProfile}
                />
              </Route>
              <Route path="/help">
                <Help />
              </Route>
              <Route path="/assets">
                <ManageAssets />
              </Route>
              <Route path="/users">
                <ManageUsers userProfile={props.userProfile} />
              </Route>
              <Route path="/buildreport">
                <BuildReport
                  selectedProject={props.selectedProject}
                  userProfile={props.userProfile}
                  loadP={loadCurrentProject}
                />
              </Route>
              <Route path="/">
                <Upload
                  selectedProject={props.selectedProject}
                  userProfile={props.userProfile}
                  loadP={loadCurrentProject}
                />
              </Route>
            </Switch>
          </AuthenticatedTemplate>
        )}
      </Router>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
