import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

const divStyle = {
    color: 'white',

};

const ProjectList = ({ projects, onDeleteClick }) => (

    <table className="table" style={divStyle}>
        <thead>
        <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Tenant</th>
            <th>Site</th>
            <th />
        </tr>
        </thead>
        <tbody>
        { projects.map(project => {
            return (
                <tr key={project.id}>
                    <td>
                        <Link to={"/project/" + project.id}>{project.name}</Link>
                    </td>
                    <td>{project.description}</td>
                    <td>{project.tenantName}</td>
                    <td>{project.siteName}</td>
                    <td>
                        <button
                            className="btn btn-outline-danger"
                            onClick={() => onDeleteClick(project)}
                        >
                            Delete
                        </button>
                    </td>
                </tr>
            );
        })}
        </tbody>
    </table>
);

function mapStateToProps(state, ownProps) {

    return {
        projects: state.projects
    };
}

ProjectList.propTypes = {
    projects: PropTypes.array.isRequired,
    onDeleteClick: PropTypes.func.isRequired
};

export default connect(
    mapStateToProps
)(ProjectList);
