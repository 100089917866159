import * as types from "./actionTypes";

export function beginApiCall(setLoaded) {
    if(setLoaded!=null) {
        return {type: types.BEGIN_API_CALL, setLoaded};
    } else {
        return {type: types.BEGIN_API_CALL};
    }
}

export function apiCallError() {
    return { type: types.API_CALL_ERROR };
}
