import React, {useState} from "react";
import { Nav, Navbar, Dropdown, DropdownButton, Button } from 'react-bootstrap';

function Help(props) {

    const [selectedTab, setSelectedTab] = useState(1);

    return (<div >

        <div style={{paddingTop: '20px',paddingBottom: '30px'}} >
            <span ><b> Choose a video below to watch an overview of how to use Clearsight Reveal</b></span>

            <ul className="nav nav-tabs">
                    <li className="nav-item">
                        <a className="nav-link" onClick={ ()=> {setSelectedTab(1)}}>Uploading Data</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" onClick={ ()=> {setSelectedTab(2)}}>Reviewing Data</a>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link" onClick={ ()=> {setSelectedTab(3)}}>Building Reports</a>
                    </li>



            </ul>

    </div><div>

        { selectedTab===1 &&
            <>
                <div><span><b>Uploading Data Tutorial</b><p></p></span>
            </div>
            <iframe
                src="https://player.vimeo.com/video/813415419?h=6a30938d50&amp;title=0&amp;byline=0&amp;portrait=0&amp;speed=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                width="1200" height="600" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen
                title="revealdemo.mp4" ></iframe>
            </>
        }


        { selectedTab===2 &&
            <>
                <div><span><b>Reviewing Data Tutorial</b><p></p></span>
                </div>
            <iframe
                src="https://player.vimeo.com/video/813415407?h=32565660ee&amp;title=0&amp;byline=0&amp;portrait=0&amp;speed=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                width="1200" height="600" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen
                title="revealdemo.mp4" ></iframe>
                </>
        }


        { selectedTab===3 &&

            <>
            <div><span><b>Building Reports Tutorial</b><p></p></span>
            </div>


            <iframe
                src="https://player.vimeo.com/video/813415387?h=69d605734e&amp;title=0&amp;byline=0&amp;portrait=0&amp;speed=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                width="1200" height="600" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen
                title="revealdemo.mp4" ></iframe>
            </>
        }



    </div>
            <script src="https://player.vimeo.com/api/player.js"></script>

    </div>);

}

export default Help;