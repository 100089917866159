import React, {useEffect,useState,useRef} from 'react'
import {Link, useHistory} from "react-router-dom";
import SelectInput from "../common/SelectInput";
import { Modal, Button } from "react-bootstrap";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as projectActions from "../../redux/actions/projectActions";
import { toast } from "react-toastify";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import * as reviewActions from "../../redux/actions/reviewActions";

function FileList(props) {


    const [showFilter, setShowFilter] = useState('All');


    function downloadFile(downloadPath,downloadFilename) {


        fetch(downloadPath, {
            method: 'GET',

        })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download

                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');

                link.href = url;
                link.setAttribute(
                    'download',
                    downloadFilename,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });

    }


    function ns(a) {
        if(a==null) {
            return 'Not Specified';

        }else {
            return a;
        }
    }
    function setAttribute(event) {
        let attributeName = event.currentTarget.name;
        let attributeValue = event.currentTarget.value;

        let newAttributeSelected = {...props.reviewState.attributeSelected};
        newAttributeSelected[attributeName] = attributeValue;

        props.actions.saveAttributeSelected(newAttributeSelected);
    }
    function changeShowFilter(event){
        setShowFilter(event.target.value);
    }


    function getDateString(inputDate) {
        let myDate = new Date(inputDate);
        return myDate.toDateString();
    }


    let at1 = props.reviewState.attributeSelected;



    let fileListing =  props.selectedProject.fileInfo.filter(fn => {
        let ima = fn.imageAttributes;
        if (ima != null && ima.length > 0) {
            ima = JSON.parse(ima)
        }

        if (fn.imageAttributes != null && fn.imageAttributes['itemDeleted'] != null && fn.imageAttributes['itemDeleted'] !== 0) {
            return false;
        }


        for(let propertyName in at1) {
            if(at1[propertyName]!=='Not Specified' && !propertyName.startsWith('internal')) {
                if(ima==null || ima[propertyName]!==at1[propertyName]) { return false;}

            }
        }


        if(showFilter!=='All') {
            if(fn.fileType!==showFilter) {
                return false;
            }
        }

        return true;
    });


    let selectControls = props.selectedProject.attributeData.map(att => {

            let selectOptions = att.choices.map((choice) =>{
                return({
                    value: choice, text: choice});
            });



            if(att.controlName==='imageComments') {
                return null;
            } else {

                return (
                    <SelectInput key={att.controlName} name={att.controlName} label={att.controlLabel}
                                 value={ns(props.reviewState.attributeSelected[att.controlName])}
                                 onChange={setAttribute} options={selectOptions}/>);
            }
        }
    );

    const sasKey = props.selectedProject.projectInfo.sasKey;
    const imageBase = props.selectedProject.projectInfo.webURL;



    return ( <>
            <p></p>
            {selectControls} Show <select value={showFilter} onChange={changeShowFilter}><option value="All">All</option>
            <option value="Image">Images</option>
            <option value="Video">Videos</option>
            <option value="Misc">Files</option>
        </select>
            <p></p>
            <b>Total number of files: {fileListing.length}</b><p></p>

            <table className="table" >
            <thead>
            <tr style={{textAlign: 'left'}}>
                <th>File Name</th>
                <th>Folder Location</th>
                <th>Date Created</th>
                <th />
            </tr>
            </thead>
            <tbody>
            { fileListing.map(fInfo => {
                return (
                    <tr key={fInfo.fileId}>
                        <td>
                            {fInfo.fileType==='Video' &&
                                <a href="" onClick={(event) =>{ event.preventDefault(); let fn1=imageBase+'/videos/'+fInfo.fileLocation+sasKey;
                                downloadFile(fn1,fInfo.fileName);
                                }}>{fInfo.fileName}</a>
                            }

                            {fInfo.fileType!=='Video' &&
                                <a href="" onClick={(event) =>{ event.preventDefault(); let fn1=imageBase+'/images/'+fInfo.fileLocation+sasKey;
                                    downloadFile(fn1,fInfo.fileName);
                                }}>{fInfo.fileName}</a>
                            }


                        </td>
                        <td>
                            {fInfo.relativePath}
                        </td>
                        <td>{getDateString(fInfo.createdAt)}</td>
                    </tr>
                );
            })}
            </tbody>
        </table></>
        );


}


function mapStateToProps(state) {
    return {
        projects: state.projects,
        selectedProject: state.selectedProject,
        loading: state.apiCallsInProgress > 0,
        userProfile: state.userProfile,
        reviewState: state.reviewState
    };
}

function mapDispatchToProps(dispatch) {
    return {

        actions: {
            changeProject: bindActionCreators(projectActions.changeProject, dispatch),
            loadProjects: bindActionCreators(projectActions.loadProjects, dispatch),
            saveAttributeAction: bindActionCreators(projectActions.saveAttributeAction, dispatch),
            saveAttribute: bindActionCreators(projectActions.saveAttributeAction, dispatch),
            saveAttributeSelected: bindActionCreators(reviewActions.saveAttributeSelected, dispatch),

        }
    };
}


export default connect(mapStateToProps,
    mapDispatchToProps)(FileList)
