const initialState = {
  projects: [],
  tenants: [],
  apiCallsInProgress: 0,
  selectedProject: {
    projectInfo: { id: -1 },
    fileInfo: [{ fileId: -1 }],
    attributeData: [
      { controlName: 'blah', controlLabel: 'blah2', choices: ['one', 'two'] },
    ],
    loaded: false,
  },
  userProfile: { userId: 1, token: '' },
  reviewState: {
    currentImage: 0,
    currentSlide: 0,
    modSelected: {},
    attributeSelected: {
      internalShowReviewed: true,
      internalShowAnnotated: true,
      internalShowType: 'All',
      internalFolderSelected: '\\',
    },
    reportSelected: {},
    taskChecked: {},
  },
};

export default initialState;

